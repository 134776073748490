import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { marked } from 'marked';
import MarkdownToolbar from '../components/MarkdownToolbar';
import apiService from '../services/apiService';
import ManagePosts from '../components/ManagePosts';
import SEOHead from '../components/SEOHead';
import EnhancedEditor from '../components/ContentEditor';

import { 
  ImageIcon, 
  Save,
  Globe,
  Calendar,
  Tag,
  AlertCircle,
  CheckCircle2,
  ArrowUp,
  LayoutGrid,
  PencilLine,
  Archive
} from 'lucide-react';

const Editor = () => {
  const navigate = useNavigate();
  const { id } = useParams();  // For editing existing posts
  const [activeView, setActiveView] = useState('new'); // 'new', 'manage'
  const [post, setPost] = useState({
    title: '',
    slug: '',
    excerpt: '',
    content: '',
    featuredImage: '',
    tags: [],
    status: 'draft',
    publishDate: new Date().toISOString().split('T')[0],
    seoTitle: '',
    seoDescription: ''
  });

  const [newTag, setNewTag] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const markdownToHtml = (markdown) => {
    try {
      return marked(markdown);
    } catch (error) {
      console.error('Markdown parsing error:', error);
      return '';
    }
  };
  const renderMarkdown = (content) => {
    try {
      return { __html: marked(content) };
    } catch (error) {
      console.error('Markdown parsing error:', error);
      return { __html: content };
    }
  };
  useEffect(() => {
    if (id && id !== 'new') {
      fetchPost();
    }
  }, [id]);

  const fetchPost = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiService.getUrl(`/api/blog/posts/edit/${id}`)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch post');
      }
  
      const data = await response.json();
      setPost(data);
    } catch (error) {
      setError('Failed to load post');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleContentImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
  
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiService.getUrl('/api/uploads/image')}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload image');
      }
  
      const data = await response.json();
      return data.url; // Return the uploaded image URL
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost(prev => ({
      ...prev,
      [name]: value
    }));

    // Auto-generate slug from title
    if (name === 'title') {
      const slug = value
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');
      setPost(prev => ({
        ...prev,
        slug
      }));
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setPost(prev => ({
        ...prev,
        featuredImage: reader.result
      }));
    };
    reader.readAsDataURL(file);
  };

  const addTag = (e) => {
    e.preventDefault();
    if (newTag.trim() && !post.tags.includes(newTag.trim())) {
      setPost(prev => ({
        ...prev,
        tags: [...prev.tags, newTag.trim()]
      }));
      setNewTag('');
    }
  };

  const removeTag = (tagToRemove) => {
    setPost(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  const handleSubmit = async (status = 'draft') => {
    try {
      setLoading(true);
      setError('');
      setSuccess('');
  
      const token = localStorage.getItem('token');
      const method = id && id !== 'new' ? 'PUT' : 'POST';
      const url = id && id !== 'new'
        ? apiService.getUrl(`/api/blog/posts/${id}`)
        : apiService.getUrl('/api/blog/posts');
  
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          ...post,
          status
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to save post');
      }
  
      const savedPost = await response.json();
      
      setSuccess(status === 'published' ? 'Post published successfully!' : 'Draft saved successfully!');
      
      // Reset form for new posts
      if (!id || id === 'new') {
        setPost({
          title: '',
          slug: '',
          excerpt: '',
          content: '',
          featuredImage: '',
          tags: [],
          status: 'draft',
          publishDate: new Date().toISOString().split('T')[0],
          seoTitle: '',
          seoDescription: ''
        });
      }
  
      setTimeout(() => {
        setSuccess('');
        if (status === 'published') {
          navigate(`/blog/${savedPost.slug}`);
        }
      }, 2000);
  
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Toggle between new post and manage posts views
  const toggleView = (view) => {
    setActiveView(view);
    if (view === 'manage') {
      navigate('/manage-posts');  // You'll need to create this route
    }
  };

  return (
    <>
      <SEOHead
  title={id ? 'Edit Post' : 'Create New Post'}
  description="Content creation editor"
  noindex={true}
/>
  <div className="min-h-screen bg-gray-50 py-8">
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* View Toggle */}
      <div className="bg-white rounded-lg shadow-sm p-4 mb-8">
        <div className="flex space-x-4">
          <button
            onClick={() => toggleView('new')}
            className={`flex items-center px-4 py-2 rounded-lg ${
              activeView === 'new'
                ? 'bg-purple-100 text-purple-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <PencilLine className="h-5 w-5 mr-2" />
            New Post
          </button>
          <button
            onClick={() => toggleView('manage')}
            className={`flex items-center px-4 py-2 rounded-lg ${
              activeView === 'manage'
                ? 'bg-purple-100 text-purple-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <LayoutGrid className="h-5 w-5 mr-2" />
            Manage Posts
          </button>
        </div>
      </div>

      {activeView === 'manage' ? (
        <ManagePosts />
      ) : (
        // Main Editor Form
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {id ? 'Edit Post' : 'Create New Post'}
              </h1>
              <p className="text-gray-600 mt-1">Write and publish your blog post</p>
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => handleSubmit('draft')}
                disabled={loading}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
              >
                Save Draft
              </button>
              <button
                onClick={() => handleSubmit('published')}
                disabled={loading}
                className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
              >
                {id && post.status === 'published' ? 'Update' : 'Publish'}
              </button>
            </div>
          </div>


          {error && (
            <div className="mb-4 flex items-center text-red-600 bg-red-50 p-3 rounded-lg">
              <AlertCircle className="h-5 w-5 mr-2" />
              {error}
            </div>
          )}

          {success && (
            <div className="mb-4 flex items-center text-green-600 bg-green-50 p-3 rounded-lg">
              <CheckCircle2 className="h-5 w-5 mr-2" />
              {success}
            </div>
          )}

          {/* Form Fields */}
          <div className="space-y-6">
            {/* Title */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Post Title
              </label>
              <input
                type="text"
                name="title"
                value={post.title}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter post title..."
              />
            </div>

            {/* Slug */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                URL Slug
              </label>
              <div className="flex items-center space-x-2">
                <Globe className="h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  name="slug"
                  value={post.slug}
                  onChange={handleInputChange}
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="your-post-url"
                />
              </div>
            </div>

            {/* Featured Image */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Featured Image
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                {post.featuredImage ? (
                  <div className="space-y-1 text-center">
                    <img
                      src={post.featuredImage}
                      alt="Preview"
                      className="mx-auto h-32 w-auto object-cover rounded-lg"
                    />
                    <button
                      onClick={() => setPost(prev => ({ ...prev, featuredImage: '' }))}
                      className="text-sm text-red-600 hover:text-red-700"
                    >
                      Remove Image
                    </button>
                  </div>
                ) : (
                  <div className="space-y-1 text-center">
                    <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <label className="relative cursor-pointer rounded-md font-medium text-purple-600 hover:text-purple-700">
                        <span>Upload a file</span>
                        <input
                          type="file"
                          className="sr-only"
                          accept="image/*"
                          onChange={handleImageUpload}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* Excerpt */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Excerpt
              </label>
              <textarea
                name="excerpt"
                value={post.excerpt}
                onChange={handleInputChange}
                rows={3}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Write a brief description..."
              />
            </div>

            {/* Content */}
            <div>
  <label className="block text-sm font-medium text-gray-700 mb-2">
    Content
  </label>
  <EnhancedEditor
    initialContent={post.content}
    onChange={(newContent) => {
      setPost(prev => ({
        ...prev,
        content: newContent
      }));
    }}
    onImageUpload={handleContentImageUpload}
  />
</div>

                {/* Preview */}
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Preview
                  </label>
                  <div 
                    className="prose max-w-none p-4 border border-gray-300 rounded-lg bg-gray-50"
                    dangerouslySetInnerHTML={renderMarkdown(post.content)}
                  />
                </div>


            {/* Tags */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Tags
              </label>
              <div className="flex flex-wrap gap-2 mb-3">
                {post.tags.map(tag => (
                  <span
                    key={tag}
                    className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800"
                  >
                    {tag}
                    <button
                      onClick={() => removeTag(tag)}
                      className="ml-2 text-purple-600 hover:text-purple-700"
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
              <form onSubmit={addTag} className="flex gap-2">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Add a tag..."
                />
                <button
                  type="submit"
                  className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                >
                  Add
                </button>
              </form>
            </div>

            {/* SEO Section */}
            <div className="border-t pt-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">SEO Settings</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    SEO Title
                  </label>
                  <input
                    type="text"
                    name="seoTitle"
                    value={post.seoTitle}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder="Enter SEO title..."
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    SEO Description
                  </label>
                  <textarea
                    name="seoDescription"
                    value={post.seoDescription}
                    onChange={handleInputChange}
                    rows={3}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder="Enter SEO description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
  </>
  );
};

export default Editor;