import React, { useState, useEffect, useRef } from 'react';
import { AlertCircle, ImagePlus } from 'lucide-react';

const EnhancedEditor = ({ initialContent = '', onChange, onImageUpload }) => {
  const [content, setContent] = useState(initialContent);
  const [showImageButton, setShowImageButton] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const textareaRef = useRef(null);

  // Clean Word content and extract links
  const cleanWordContent = (html) => {
    // First remove Word metadata
    html = html.replace(/<!\[if.*?\]>.*?<!\[endif\]>/gi, '');
    html = html.replace(/<style>[\s\S]*?<\/style>/gi, '');
    html = html.replace(/<!--[\s\S]*?-->/g, '');
    html = html.replace(/style="[^"]*"/gi, '');
    html = html.replace(/class="[^"]*"/gi, '');
    html = html.replace(/Normal\s*\d*\s*false\s*false\s*false\s*EN-US\s*X-NONE\s*AR-SA/gi, '');
    html = html.replace(/table\.MsoNormalTable[\s\S]*?}/gi, '');

    // Special handling for Word hyperlinks
    html = html.replace(/<a[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/gi, (match, url, text) => {
      // Clean up Word's complex URLs
      if (url.startsWith('file:///')) {
        // Extract actual URL from Word's file protocol links
        const urlMatch = url.match(/OriginalUrl=([^&]*)/);
        if (urlMatch) {
          url = decodeURIComponent(urlMatch[1]);
        }
      }
      // Return proper markdown link
      return `[${text}](${url})`;
    });

    // Convert other HTML to Markdown
    return html
      .replace(/<h1[^>]*>(.*?)<\/h1>/gi, '# $1\n\n')
      .replace(/<h2[^>]*>(.*?)<\/h2>/gi, '## $1\n\n')
      .replace(/<h3[^>]*>(.*?)<\/h3>/gi, '### $1\n\n')
      .replace(/<strong[^>]*>(.*?)<\/strong>/gi, '**$1**')
      .replace(/<b[^>]*>(.*?)<\/b>/gi, '**$1**')
      .replace(/<em[^>]*>(.*?)<\/em>/gi, '_$1_')
      .replace(/<i[^>]*>(.*?)<\/i>/gi, '_$1_')
      .replace(/<ul[^>]*>(.*?)<\/ul>/gi, '$1\n')
      .replace(/<ol[^>]*>(.*?)<\/ol>/gi, '$1\n')
      .replace(/<li[^>]*>(.*?)<\/li>/gi, '* $1\n')
      .replace(/<p[^>]*>(.*?)<\/p>/gi, '$1\n\n')
      .replace(/<[^>]*>/g, '')
      .replace(/\n\s*\n\s*\n/g, '\n\n')
      .trim();
  };

  // Handle paste events
  const handlePaste = async (e) => {
    e.preventDefault();
    
    const clipboardData = e.clipboardData;
    let htmlContent = clipboardData.getData('text/html');
    
    if (htmlContent) {
      // Clean and convert content
      const cleanedContent = cleanWordContent(htmlContent);
      
      // Insert at cursor position
      const textarea = e.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const textBefore = content.substring(0, start);
      const textAfter = content.substring(end);
      
      const newContent = textBefore + cleanedContent + textAfter;
      setContent(newContent);
      onChange(newContent);

      // Set cursor position
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + cleanedContent.length;
      }, 0);
    } else {
      // Fallback to plain text
      const text = clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
    }
  };

  // Handle cursor movement
  const handleSelect = (e) => {
    const cursorPos = e.target.selectionStart;
    setCursorPosition(cursorPos);
    
    const textBeforeCursor = content.substring(0, cursorPos);
    const isNewLine = textBeforeCursor.endsWith('\n') || textBeforeCursor === '';
    setShowImageButton(isNewLine);
  };

  // Handle image upload
  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      
      // Call the provided upload function
      const imageUrl = await onImageUpload(file);
      
      if (!imageUrl) {
        throw new Error('No image URL returned');
      }

      // Insert image markdown at cursor position
      const imageMarkdown = `![${file.name}](${imageUrl})\n\n`;
      const newContent = 
        content.substring(0, cursorPosition) +
        imageMarkdown +
        content.substring(cursorPosition);
      
      setContent(newContent);
      onChange(newContent);
      setShowImageButton(false);

    } catch (error) {
      console.error('Failed to upload image:', error);
      alert('Failed to upload image. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center text-sm text-blue-500 bg-blue-500/10 p-3 rounded-lg">
        <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
        <p>
          Pro tip: Write and format your content in Microsoft Word, then copy and paste it here. 
          To add images, place your cursor at the start of a new line.
        </p>
      </div>
      
      <div className="relative">
        <textarea
          ref={textareaRef}
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
            onChange(e.target.value);
          }}
          onSelect={handleSelect}
          onPaste={handlePaste}
          className="w-full h-96 px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent font-mono bg-white/5 text-gray-100"
          placeholder="Write or paste your content here..."
        />
        
        {showImageButton && (
          <div className="absolute left-2 transform -translate-y-1/2" 
               style={{ top: `${(cursorPosition / Math.max(content.length, 1)) * 100}%` }}>
            <label className={`inline-flex items-center px-3 py-1.5 rounded-lg cursor-pointer transition-colors ${
              isUploading 
                ? 'bg-gray-500 cursor-wait' 
                : 'bg-purple-600 hover:bg-purple-700'
            } text-white`}>
              <ImagePlus className="h-4 w-4 mr-2" />
              {isUploading ? 'Uploading...' : 'Add Image'}
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleImageUpload}
                disabled={isUploading}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnhancedEditor;