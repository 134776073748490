export const initializeGumroadPayment = ({ productId, email, userId, metadata = {} }) => {
  try {
    // Combine metadata with required fields
    const customFields = {
      userId: userId.toString(),
      email: email.toLowerCase(),
      ...metadata
    };

    // Base Gumroad product URL
    const baseUrl = `https://thecontentgpt.gumroad.com/l/${productId}`;
    
    // Construct URL parameters
    const params = new URLSearchParams({
      email: email,
      wanted: 'true',
      custom_fields: JSON.stringify(customFields) // Use custom_fields instead of custom_data
    });

    const paymentUrl = `${baseUrl}?${params.toString()}`;

    // Open Gumroad overlay
    const width = 800;
    const height = 800;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    window.open(
      paymentUrl,
      'GumroadOverlay',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    return true;
  } catch (error) {
    console.error('Error initializing Gumroad payment:', error);
    throw error;
  }
};