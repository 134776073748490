import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import apiService from '../services/apiService';
import ReactMarkdown from 'react-markdown';
import { Command, CommandInput } from "../components/ui/command";
import {
  Menu,
  MessageCircle,
  Settings,
  Plus,
  Send,
  Loader2,
  ChevronDown,
  X,
  RefreshCw,
  MoreVertical,
  Trash2,
  Edit,
  Save,
  Copy,
  Check,
  AlertCircle
} from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { ScrollArea } from "../components/ui/scroll-area";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Alert, AlertDescription } from "../components/ui/alert";

const ChatBot = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 1024);
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedModel, setSelectedModel] = useState('google/gemini-pro');
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [models, setModels] = useState([]);
const [loadingModels, setLoadingModels] = useState(true);
const [modelSearchQuery, setModelSearchQuery] = useState('');
const [isOpen, setIsOpen] = useState(false);
const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
const modelDropdownRef = useRef(null);
const [isHovering, setIsHovering] = useState(false);


// Add a function to filter models
const filteredModels = models.filter(model => 
    model.name.toLowerCase().includes(modelSearchQuery.toLowerCase()) ||
    model.id.toLowerCase().includes(modelSearchQuery.toLowerCase())
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (modelDropdownRef.current && !modelDropdownRef.current.contains(event.target)) {
        setIsModelDropdownOpen(false);
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add this useEffect to fetch models
useEffect(() => {
    const fetchModels = async () => {
      try {
        setLoadingModels(true);
        const response = await apiService.fetch('/api/models');
        const sortedModels = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setModels(sortedModels);
        
        // Set default model to first available one
        if (sortedModels.length > 0) {
          setSelectedModel(sortedModels[0].id);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load models');
      } finally {
        setLoadingModels(false);
      }
    };
  
    fetchModels();
  }, []);

  // Scroll to bottom effect
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Load conversations on mount
  useEffect(() => {
    loadConversations();
  }, []);

  // Load messages when conversation changes
  useEffect(() => {
    if (currentConversation) {
      loadMessages(currentConversation.id);
    }
  }, [currentConversation]);

  const loadConversations = async () => {
    try {
      const response = await apiService.fetch('/api/conversations');
      setConversations(response.conversations);
      
      // Set the most recent conversation as current if none selected
      if (!currentConversation && response.conversations.length > 0) {
        setCurrentConversation(response.conversations[0]);
      }
    } catch (error) {
      setError('Failed to load conversations');
      console.error('Error loading conversations:', error);
    }
  };

  const loadMessages = async (conversationId) => {
    try {
      const response = await apiService.fetch(`/api/conversations/${conversationId}/messages`);
      setMessages(response.messages);
    } catch (error) {
      setError('Failed to load messages');
      console.error('Error loading messages:', error);
    }
  };

  const createNewConversation = async () => {
    try {
      const response = await apiService.post('/api/conversations', {
        title: 'New Chat',
        model: selectedModel
      });
      
      setConversations([response.conversation, ...conversations]);
      setCurrentConversation(response.conversation);
      setMessages([]);
    } catch (error) {
      setError('Failed to create new conversation');
      console.error('Error creating conversation:', error);
    }
  };

  const handleModelChange = (modelId) => {
    setSelectedModel(modelId);
  };

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isProcessing || !currentConversation) {
      return;
    }
  
    setIsProcessing(true);
    setError(null);
  
    try {
      // Create new conversation if none exists
      if (!currentConversation) {
        const newConversation = await apiService.post('/api/conversations', {
          title: 'New Chat',
          model: selectedModel
        });
        setCurrentConversation(newConversation.conversation);
        setConversations([newConversation.conversation, ...conversations]);
      }
  
      // Rest of your existing handleMessageSubmit code...
    } catch (error) {
      setError('Failed to send message');
      console.error('Error sending message:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCopyMessage = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy message:', error);
    }
  };

  const handleEditMessage = (messageId, content) => {
    setEditingMessageId(messageId);
    setEditedContent(content);
  };

  const saveEditedMessage = async (messageId) => {
    try {
      await apiService.put(`/api/messages/${messageId}`, {
        content: editedContent
      });
      
      setMessages(prev =>
        prev.map(msg =>
          msg.id === messageId
            ? { ...msg, content: editedContent }
            : msg
        )
      );
      
      setEditingMessageId(null);
      setEditedContent('');
    } catch (error) {
      setError('Failed to save edited message');
      console.error('Error saving edited message:', error);
    }
  };

  const deleteMessage = async (messageId) => {
    try {
      await apiService.delete(`/api/messages/${messageId}`);
      setMessages(prev => prev.filter(msg => msg.id !== messageId));
    } catch (error) {
      setError('Failed to delete message');
      console.error('Error deleting message:', error);
    }
  };

  // Sidebar Component
  const Sidebar = ({ isExpanded }) => (
    <>
      {/* Mobile Overlay remains unchanged */}
      {isSidebarOpen && window.innerWidth < 1024 && (
      <div
        className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-40 lg:hidden"
        onClick={() => setIsSidebarOpen(false)}
      />
    )}
  
      {/* Updated Sidebar */}
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={`fixed top-16 left-0 h-[calc(100vh-64px)] bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 z-30 transform transition-all duration-300 ease-out ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 lg:transition-[width] ${
          isHovering ? 'lg:w-64 lg:overflow-visible' : 'lg:w-2 lg:overflow-hidden'
        }`}
      >

        {/* Close button - Mobile only */}
        <button
          className="lg:hidden absolute top-4 right-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
          onClick={() => setIsSidebarOpen(false)}
        >
          <X className="h-6 w-6" />
        </button>

        {/* New Chat Button */}
        <div className="p-4">
          <Button
            onClick={createNewConversation}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white"
          >
            <Plus className="h-5 w-5 mr-2" />
            New Chat
          </Button>
        </div>

        
        <div className="px-4 mb-4 relative" ref={modelDropdownRef}>
  <button
    onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
    className="w-full flex items-center justify-between px-3 py-2 text-sm border border-gray-200 dark:border-gray-700 rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
  >
    <span className="truncate">
      {loadingModels 
        ? "Loading models..." 
        : (models.find(m => m.id === selectedModel)?.name || "Select Model")}
    </span>
    <ChevronDown className={`h-4 w-4 transition-transform ${isModelDropdownOpen ? 'transform rotate-180' : ''}`} />
  </button>

  {isModelDropdownOpen && (
    <div className="absolute left-4 right-4 top-full mt-1 z-50 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg">
      <div className="p-2 border-b border-gray-200 dark:border-gray-700">
        <input
          type="text"
          placeholder="Search models..."
          value={modelSearchQuery}
          onChange={(e) => setModelSearchQuery(e.target.value)}
          className="w-full px-3 py-1 text-sm bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 dark:text-white"
          autoComplete="off"
          autoFocus
        />
      </div>
      <div className="max-h-[200px] overflow-y-auto">
        {filteredModels.length > 0 ? (
          filteredModels.map((model) => (
            <button
              key={model.id}
              onClick={() => {
                handleModelChange(model.id);
                setIsModelDropdownOpen(false);
                setModelSearchQuery('');
              }}
              className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-900 dark:text-white"
            >
              {model.name}
            </button>
          ))
        ) : (
          <div className="py-2 px-4 text-sm text-gray-500 dark:text-gray-400">
            No models found
          </div>
        )}
      </div>
    </div>
  )}
</div>

        {/* Conversations List */}
        <ScrollArea className="h-[calc(100vh-180px)]">
          <div className="px-4 py-2 space-y-2">
            {conversations.map((conversation) => (
              <button
                key={conversation.id}
                onClick={() => setCurrentConversation(conversation)}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
                  currentConversation?.id === conversation.id
                    ? 'bg-purple-50 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400'
                    : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                }`}
              >
                <div className="flex items-center">
                  <MessageCircle className="h-4 w-4 mr-2" />
                  <span className="truncate">{conversation.title}</span>
                </div>
              </button>
            ))}
          </div>
        </ScrollArea>
      </div>
    </>
  );

  
return (
  <div className="flex min-h-screen bg-gray-50 dark:bg-gray-900">
    {/* Sidebar */}
    <Sidebar />

    {/* Main Content Area */}
    <div className="flex-1 flex flex-col min-w-0">
      {/* Mobile Header - Fixed Position */}
      <div className="lg:hidden sticky top-0 z-30 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center justify-between p-4">
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="p-2 rounded-lg text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <Menu className="h-6 w-6" />
          </button>
          <div className="flex items-center space-x-3">
            <div className="h-8 w-8 bg-purple-600 rounded-lg flex items-center justify-center">
              <span className="text-white font-bold text-xl">C</span>
            </div>
          </div>
        </div>
      </div>

      {/* Messages Area - Scrollable */}
      <div className="flex-1 overflow-hidden relative">
        <ScrollArea className="h-[calc(100vh-140px)] lg:h-[calc(100vh-80px)]">
          <div className="max-w-4xl mx-auto space-y-6 p-4">
              {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.role === 'user' ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`max-w-3xl rounded-lg p-4 ${
                    message.role === 'user'
                      ? 'bg-purple-600 text-white'
                      : 'bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700'
                  }`}
                >
                  {editingMessageId === message.id ? (
                    <div className="space-y-2">
                      <Textarea
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                        className="min-h-[100px]"
                      />
                      <div className="flex justify-end space-x-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setEditingMessageId(null)}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => saveEditedMessage(message.id)}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="relative group">
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                      
                      <div className="absolute top-0 right-0 opacity-0 group-hover:opacity-100 transition-opacity">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="sm">
                              <MoreVertical className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem onClick={() => handleCopyMessage(message.content)}>
                              <Copy className="h-4 w-4 mr-2" />
                              Copy
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => handleEditMessage(message.id, message.content)}>
                              <Edit className="h-4 w-4 mr-2" />
                              Edit
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => deleteMessage(message.id)}>
                              <Trash2 className="h-4 w-4 mr-2" />
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </ScrollArea>
      </div>

      {/* Input Area - Fixed at Bottom */}
      <div className="sticky bottom-0 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 p-4">
        <div className="max-w-4xl mx-auto">
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          <form onSubmit={handleMessageSubmit} className="flex gap-4">
            <Textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type your message here..."
              className="flex-1 min-h-[60px] max-h-[200px] resize-none"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleMessageSubmit(e);
                }
              }}
            />
            <Button 
              type="submit" 
              disabled={isProcessing || !inputMessage.trim()}
              className="self-end"
            >
              {isProcessing ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                <Send className="h-5 w-5" />
              )}
            </Button>
          </form>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
            Press Enter to send, Shift + Enter for new line
          </p>
        </div>
      </div>
    </div>
  </div>
);
};

export default ChatBot;  