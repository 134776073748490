import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import apiService from '../../services/apiService';
import SEOHead from '../SEOHead';
import { 
  Wand2, 
  Copy, 
  CheckCircle2,
  AlertCircle,
  Sparkles,
  Settings,
  Target,
  Star,
  MessageCircle,
  HelpCircle
} from 'lucide-react';
import { getTemplateIcon } from '../../config/templateConfig';
import { useTheme } from '../../context/ThemeContext';
import TemplateService from '../../services/TemplateService';

const GenericTemplateWriter = () => {
  const location = useLocation();
  const [template, setTemplate] = useState({
    name: '',
    description: '',
    category: '',
    identifier: '',
    pointsCost: 1
  });
  const [formData, setFormData] = useState({});
  const [userPoints, setUserPoints] = useState(0);
  const [generatedContent, setGeneratedContent] = useState({
    text: '',
    formattedText: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [error, setError] = useState('');
  const [fields, setFields] = useState([]);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const { theme } = useTheme();
  const Icon = template ? getTemplateIcon(template.category) : Star;

  // Format content function
  const formatContent = (text) => {
    if (!text) return '';
    
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    formattedText = formattedText.replace(/^# (.*?)$/gm, '<h1 class="text-2xl font-bold my-4">$1</h1>');
    formattedText = formattedText.replace(/^## (.*?)$/gm, '<h2 class="text-xl font-bold my-3">$1</h2>');
    formattedText = formattedText.replace(/^### (.*?)$/gm, '<h3 class="text-lg font-bold my-2">$1</h3>');
    formattedText = formattedText.replace(/\n/g, '<br />');
    
    return formattedText;
  };

  useEffect(() => {
    const loadTemplate = async () => {
      try {
        setIsProcessing(true);
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication required');
        }
        
        const path = location.pathname.replace('/tools/', '');
        const response = await fetch(apiService.getUrl('/api/templates'), {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-store'
          }
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch templates');
        }
    
        const templates = await response.json();
        const templateData = Object.values(templates).find(t => 
          t.identifier === path || 
          t.name.toLowerCase().replace(/[^a-z0-9]+/g, '-') === path
        );
        
        if (templateData) {
          try {
            const promptConfig = JSON.parse(templateData.pythonCode);
            const extractedFields = new Set();
            promptConfig.messages.forEach(msg => {
              msg.content.forEach(content => {
                const matches = content.text.match(/{([^}]+)}/g) || [];
                matches.forEach(match => {
                  extractedFields.add(match.slice(1, -1));
                });
              });
            });
            
            const fields = Array.from(extractedFields);
            setTemplate(templateData);
            setFields(fields);
            
            const initialData = fields.reduce((acc, field) => {
              if (field.includes('length') || field.includes('count')) {
                acc[field] = '500';
              } else if (field.includes('tone')) {
                acc[field] = 'professional';
              } else {
                acc[field] = '';
              }
              return acc;
            }, {});
            setFormData(initialData);
          } catch (error) {
            console.error('Error parsing template config:', error);
            // Fallback to legacy field extraction
            const fieldRegex = /{(\w+)}/g;
            const matches = templateData.pythonCode?.match(fieldRegex) || [];
            const extractedFields = [...new Set(matches.map(m => m.slice(1, -1)))];
            setFields(extractedFields);
            
            const initialData = extractedFields.reduce((acc, field) => {
              if (field.includes('length') || field.includes('count')) {
                acc[field] = '500';
              } else if (field.includes('tone')) {
                acc[field] = 'professional';
              } else {
                acc[field] = '';
              }
              return acc;
            }, {});
            setFormData(initialData);
          }
        }
      } catch (error) {
        console.error('Error loading template:', error);
        setError('Failed to load template');
      } finally {
        setIsProcessing(false);
      }
    };
  
    loadTemplate();
  }, [location]);

  // Load user points
  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const response = await fetch(apiService.getUrl('/api/points'), {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch points');
        }

        const data = await response.json();
        setUserPoints(data.balance || 0);
      } catch (error) {
        console.error('Error fetching points:', error);
        setError('Failed to load points balance');
      }
    };
  
    fetchPoints();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (error) setError('');

    // Update estimated cost for numeric fields
    if (name.includes('length') || name.includes('count')) {
      const numericValue = parseInt(value) || 0;
      const pointCost = numericValue * (template?.pointsCost || 1);
      setEstimatedCost(pointCost);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(generatedContent.text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      setError('Failed to copy to clipboard');
    }
  };

  const handleGenerate = async () => {
    // Reset any existing errors
    setError('');
  
    // Validate required fields
    const missingFields = fields.filter(field => !formData[field]?.trim());
    if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map(field => formatLabel(field));
      setError(`Please fill in the following field${missingFields.length > 1 ? 's' : ''}: ${missingFieldNames.join(', ')}`);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
  
    setIsProcessing(true);
    setGeneratedContent({ text: '', formattedText: '' });
  
    try {
      const token = localStorage.getItem('token');
      
      // Calculate points
      const inputWordCount = parseInt(formData.length || formData.wordCount || 500);
      const pointsPerWord = template?.pointsCost || 1;
      const pointsToDeduct = inputWordCount * pointsPerWord;
  
      if (userPoints < pointsToDeduct) {
        throw new Error(`You need ${pointsToDeduct} points to generate this content. Current balance: ${userPoints} points`);
      }
  
      let requestBody;
      try {
        if (template?.pythonCode) {
          // Parse the template config
          const promptConfig = JSON.parse(template.pythonCode);
          
          // Create a deep copy
          const configCopy = JSON.parse(JSON.stringify(promptConfig));
          
          // Replace all placeholders in messages
          configCopy.messages = configCopy.messages.map(msg => ({
            ...msg,
            content: msg.content.map(contentBlock => ({
              ...contentBlock,
              text: Object.entries(formData).reduce((text, [key, value]) => {
                return text.replace(new RegExp(`{${key}}`, 'g'), value);
              }, contentBlock.text)
            }))
          }));
  
          requestBody = {
            ...configCopy,
            metadata: {
              input_word_count: inputWordCount,
              points_to_deduct: pointsToDeduct,
              feature: template.identifier || 'generic-content'
            }
          };
        } else {
          throw new Error('Unable to load template configuration. Please try again later.');
        }
      } catch (error) {
        console.error('Error preparing request:', error);
        throw new Error('Failed to prepare generation request');
      }
  
      const response = await fetch(apiService.getUrl('/api/proxy/anthropic/stream'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody)
      });

      

      if (!response.ok) {
        throw new Error('Unable to connect to the server. Please try again later.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedText = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data.trim() === '[DONE]') continue;
            
            try {
              const parsed = JSON.parse(data);
              if (parsed.type === 'content_block_delta' && parsed.delta?.text) {
                accumulatedText += parsed.delta.text;
                setGeneratedContent({
                  text: accumulatedText,
                  formattedText: formatContent(accumulatedText)
                });
              } else if (parsed.type === 'points_update') {
                setUserPoints(parsed.remaining_balance);
                window.dispatchEvent(new CustomEvent('pointsUpdate', { 
                  detail: { balance: parsed.remaining_balance }
                }));
              }
            } catch (e) {
              console.error('Stream parsing error:', e);
            }
          }
        }
      }

    } catch (err) {
      console.error('Generation error:', err);
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const shouldBeTextarea = (fieldName) => {
    const textareaFields = ['description', 'outline', 'content', 'keywords', 'notes', 'details', 'brief', 'instructions'];
    return textareaFields.some(field => fieldName.toLowerCase().includes(field));
  };

  const formatLabel = (field) => {
    return field
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase())
      .trim();
  };

  return (
    <>
      <SEOHead
        title={`${template?.name || 'Content Generator'} | AI Content Generator`}
        description={template?.description || 'AI-powered content generation tool'}
        keywords={`${(template?.name || 'content').toLowerCase()}, ai content generator, ${template?.category || 'writing'}`}
        noindex={true}
      />
      
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="min-h-screen w-full bg-gray-50 dark:bg-gray-900 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white mb-4">
              {template?.name || 'Content Generator'}
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-400">
              {template?.description || 'Transform your ideas into engaging content optimized for search engines.'}
            </p>
          </div>

          {/* Error Display */}
          {error && (
            <div className="mb-6 bg-red-50 dark:bg-red-900/50 border border-red-200 dark:border-red-800 rounded-lg p-4">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-500 dark:text-red-400 mr-2" />
                <p className="text-red-700 dark:text-red-200">{error}</p>
              </div>
            </div>
          )}

          <div className="grid md:grid-cols-2 gap-8">
            {/* Input Form */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
              <div className="space-y-4">
                {fields.map(field => (
                  <div key={field} className="w-full">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      {formatLabel(field)}
                    </label>
                    {shouldBeTextarea(field) ? (
                      <textarea
                        name={field}
                        value={formData[field] || ''}
                        onChange={handleInputChange}
                        rows="4"
                        className={`w-full px-4 py-2 bg-white dark:bg-gray-700 border rounded-lg text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                          error && !formData[field]?.trim()
                            ? 'border-red-300 dark:border-red-700'
                            : 'border-gray-300 dark:border-gray-600'
                        }`}
                        placeholder={`Enter ${formatLabel(field).toLowerCase()}...`}
                      />
                    ) : (
                      <input
                        type="text"
                        name={field}
                        value={formData[field] || ''}
                        onChange={handleInputChange}
                        className={`w-full px-4 py-2 bg-white dark:bg-gray-700 border rounded-lg text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                          error && !formData[field]?.trim()
                            ? 'border-red-300 dark:border-red-700'
                            : 'border-gray-300 dark:border-gray-600'
                        }`}
                        placeholder={`Enter ${formatLabel(field).toLowerCase()}...`}
                      />
                    )}
                  </div>
                ))}

                {/* Points Info */}
                <div className="bg-purple-50 dark:bg-purple-900/50 rounded-lg p-6 mt-6">
                  <div className="flex items-center justify-between text-sm mb-2">
                    <span className="text-purple-700 dark:text-purple-300">Estimated Cost:</span>
                    <span className="font-medium text-purple-700 dark:text-purple-300">
                      {estimatedCost} points
                    </span>
                  </div>
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-purple-700 dark:text-purple-300">Your Balance:</span>
                    <span className="font-medium text-purple-700 dark:text-purple-300">
                      {userPoints} points
                    </span>
                  </div>
                </div>

                {/* Generate Button */}
                <button
                  onClick={handleGenerate}
                  disabled={isProcessing}
                  className={`w-full flex items-center justify-center px-4 py-3 rounded-lg text-white font-medium ${
                    isProcessing
                      ? 'bg-purple-400 dark:bg-purple-600 cursor-not-allowed'
                      : 'bg-purple-600 hover:bg-purple-700 dark:bg-purple-600 dark:hover:bg-purple-700'
                  } transition-colors mt-6`}
                >
                  {isProcessing ? (
                    <>
                      <Sparkles className="animate-spin h-5 w-5 mr-2" />
                      Generating...
                    </>
                  ) : (
                    <>
                      <Wand2 className="h-5 w-5 mr-2" />
                      Generate Content
                    </>
                  )}
                </button>
              </div>
            </div>

{/* Output Section */}
<div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white">Generated Content</h2>
          {generatedContent.text && (
            <button
              onClick={handleCopy}
              className="text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300"
              title="Copy to clipboard"
            >
              {copySuccess ? (
                <CheckCircle2 className="h-5 w-5 text-green-500 dark:text-green-400" />
              ) : (
                <Copy className="h-5 w-5" />
              )}
            </button>
          )}
        </div>

        <div className="relative min-h-[600px]">
          {generatedContent.text ? (
            <div className="absolute inset-0 overflow-y-auto p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
              <div 
                className="prose dark:prose-invert max-w-none text-gray-900 dark:text-gray-100"
                dangerouslySetInnerHTML={{ 
                  __html: generatedContent.formattedText 
                }}
              />
            </div>
          ) : (
            <div className="absolute inset-0 flex items-center justify-center text-center text-gray-500 dark:text-gray-400 border border-gray-200 dark:border-gray-700 rounded-lg">
              <div>
                <Icon className="h-12 w-12 mx-auto mb-4 opacity-50" />
                <p>Your generated content will appear here</p>
                <p className="text-sm">Fill in the details and click "Generate Content"</p>
              </div>
            </div>
          )}
        </div>
      </div>
          </div>

        {/* Features Section */}
        <div className="mt-16">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-8 text-center">Key Features</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
              <Target className="h-8 w-8 text-purple-600 dark:text-purple-400 mb-4" />
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Purpose-Built</h3>
              <p className="text-gray-600 dark:text-gray-400">Content specifically tailored to your needs and requirements.</p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
              <Star className="h-8 w-8 text-purple-600 dark:text-purple-400 mb-4" />
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Quality Assured</h3>
              <p className="text-gray-600 dark:text-gray-400">High-quality output with attention to detail and relevance.</p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
              <Settings className="h-8 w-8 text-purple-600 dark:text-purple-400 mb-4" />
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Customizable</h3>
              <p className="text-gray-600 dark:text-gray-400">Adjust settings to match your specific content requirements.</p>
            </div>
          </div>
        </div>

        {/* Point System Info */}
        <div className="mt-8 bg-purple-50 dark:bg-purple-900/50 rounded-xl p-6">
          <div className="max-w-3xl mx-auto text-center">
            <h3 className="text-lg font-semibold text-purple-900 dark:text-purple-100 mb-2">Points System</h3>
            <p className="text-purple-700 dark:text-purple-300">
              This tool uses {template?.pointsCost || 1} point{template?.pointsCost !== 1 ? 's' : ''} per word. 
              Points are deducted based on the length you specify.
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
     
  
    </>
  );
};

export default GenericTemplateWriter;