import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';



// Default metadata configuration
const defaultMetadata = {
  siteName: 'The Content GPT',
  baseUrl: 'https://thecontentgpt.com',
  defaultTitle: 'The Content GPT - AI Content Writing & Generation Platform',
  defaultDescription: 'Transform your content creation with AI-powered writing tools. Generate blogs, social media posts, emails, and more that engage your audience and rank high on search engines.',
  defaultKeywords: 'AI content writer, content generation, blog writing, AI writing assistant, content creation, SEO content, social media content',
  defaultImage: 'https://thecontentgpt.com/og-image.jpg',
  twitterHandle: '@contentgpt',
  logoUrl: 'https://thecontentgpt.com/logo.png',
  themeColor: '#7C3AED', // Purple theme color
  alternateLanguages: [
    { hrefLang: 'x-default', href: 'https://thecontentgpt.com' },
    { hrefLang: 'en', href: 'https://thecontentgpt.com' }
  ]
};


const SEOHead = ({ 
  title,
  description,
  keywords,
  image,
  article = false,
  publishDate,
  modifiedDate,
  author,
  noindex = false,
  post = null
}) => {
  const location = useLocation();
  const path = location.pathname.toLowerCase();
  const currentUrl = `${defaultMetadata.baseUrl}${path}`;

  
// Route-specific metadata configuration
const routeMetadata = {
  '/': {
    title: 'The Content GPT - AI-Powered Content Writing Platform',
    description: 'Create high-quality, SEO-optimized content in seconds with our AI writing assistant. Perfect for blogs, social media, and marketing content.',
    keywords: 'AI writer, content generation platform, automated content creation, SEO content generator',
    jsonLd: {
      '@type': 'WebApplication',
      name: 'The Content GPT',
      description: 'AI-powered content writing platform',
      applicationCategory: 'BusinessApplication',
      operatingSystem: 'Web'
    }
  },
  '/blog': {
    title: 'AI Content Writing Blog - Tips, Guides & Updates | The Content GPT',
    description: 'Expert tips and guides on content writing, SEO, and making the most of AI writing tools. Stay updated with the latest in content creation.',
    keywords: 'content writing tips, AI writing guide, content marketing, SEO writing tips',
    jsonLd: {
      '@type': 'Blog',
      name: 'The Content GPT Blog',
      description: 'Expert content writing and AI tips'
    },
    // Remove the noindex directive
    robots: 'index, follow'  // Changed from 'noindex, follow'
},

  
  '/pricing': {
    title: 'Simple & Flexible Pricing Plans | The Content GPT',
    description: 'Affordable AI content writing plans for businesses of all sizes. Start free, upgrade anytime. No credit card required.',
    keywords: 'AI writer pricing, content generation cost, writing platform plans',
    jsonLd: {
      '@type': 'Product',
      name: 'The Content GPT Subscriptions',
      description: 'AI content writing platform subscriptions'
    }
  },
  '/about': {
  title: 'About Us | The Content GPT - AI-Powered Content Creation',
  description: 'Learn about The Content GPT\'s mission to revolutionize content creation through advanced AI technology. Meet our team and discover how we\'re transforming digital content.',
  keywords: 'about content gpt, AI writing company, content creation team, AI technology company',
  jsonLd: {
    '@type': 'AboutPage',
    name: 'About The Content GPT',
    description: 'Learn about our mission and team',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://thecontentgpt.com/about'
    }
  }
},

'/blog/:slug': {
  getMetadata: (slug, blogData) => ({
    title: `${blogData.title} | The Content GPT Blog`,
    description: blogData.excerpt || blogData.description,
    keywords: blogData.tags?.join(', ') || defaultMetadata.defaultKeywords,
    jsonLd: {
      '@type': 'BlogPosting',
      headline: blogData.title,
      description: blogData.excerpt,
      author: {
        '@type': 'Person',
        name: blogData.author || 'The Content GPT Team'
      },
      datePublished: blogData.publishDate,
      dateModified: blogData.modifiedDate || blogData.publishDate,
      image: blogData.featuredImage || defaultMetadata.defaultImage,
      keywords: blogData.tags,
      articleSection: blogData.category,
      wordCount: blogData.wordCount
    }
  })
},

'/support': {
  title: 'Customer Support & Help Center | The Content GPT',
  description: '24/7 customer support for all your content creation needs. Get help with our AI writing tools, account management, and technical questions.',
  keywords: 'content gpt support, AI writer help, content creation support, customer service',
  jsonLd: {
    '@type': 'CustomerService',
    name: 'The Content GPT Support',
    description: 'Customer support and help center',
    telephone: '+1-324-244-2134',
    email: 'support@thecontentgpt.com'
  }
},

'/contact': {
  title: 'Contact Us | The Content GPT - Get in Touch',
  description: 'Contact our team for support, partnerships, or general inquiries. We\'re here to help you with all your AI content creation needs.',
  keywords: 'contact content gpt, AI writing support, content creation help, customer service contact',
  jsonLd: {
    '@type': 'ContactPage',
    name: 'Contact The Content GPT',
    description: 'Get in touch with our team'
  }
},

'/affiliate': {
  title: 'Affiliate Program | The Content GPT - Earn Commission',
  description: 'Join our affiliate program and earn up to 30% commission by promoting The Content GPT. Partner with the leading AI content creation platform.',
  keywords: 'content gpt affiliate, AI writer affiliate, content creation partnership, affiliate marketing',
  jsonLd: {
    '@type': 'Product',
    name: 'The Content GPT Affiliate Program',
    description: 'Earn commission by promoting AI content creation tools',
    offers: {
      '@type': 'Offer',
      description: 'Up to 30% commission on referred sales'
    }
  }
},

'/terms': {
  title: 'Terms of Service | The Content GPT',
  description: 'Read our terms of service and user agreement. Learn about the rules and guidelines for using The Content GPT\'s AI content creation platform.',
  keywords: 'terms of service, content gpt terms, AI writer terms, user agreement',
  jsonLd: {
    '@type': 'WebPage',
    name: 'Terms of Service',
    description: 'Legal terms and conditions for using The Content GPT'
  }
},

'/privacy': {
  title: 'Privacy Policy | The Content GPT',
  description: 'Our commitment to protecting your privacy. Learn how we collect, use, and protect your data when using The Content GPT\'s services.',
  keywords: 'privacy policy, data protection, content gpt privacy, AI writer security',
  jsonLd: {
    '@type': 'WebPage',
    name: 'Privacy Policy',
    description: 'Privacy and data protection policies'
  }
},

'/refund': {
  title: 'Refund Policy | The Content GPT',
  description: 'Learn about our 14-day money-back guarantee and refund process. We ensure your satisfaction with our AI content creation services.',
  keywords: 'refund policy, money back guarantee, content gpt refund, service guarantee',
  jsonLd: {
    '@type': 'WebPage',
    name: 'Refund Policy',
    description: '14-day money-back guarantee terms and conditions'
  }
},

'/landing/blog-writer': {
  title: 'AI Blog Writer | Create Engaging Blog Posts Instantly',
  description: 'Generate SEO-optimized blog posts in minutes with our AI blog writer. Create engaging content that ranks high on search engines.',
  keywords: 'AI blog writer, blog post generator, content creation tool, SEO blog writing',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Blog Writer',
    applicationCategory: 'Content Creation',
    description: 'AI-powered blog post generator'
  }
},

'/landing/social-media-writer': {
  title: 'AI Social Media Content Creator | Engaging Posts Generator',
  description: 'Create engaging social media content for all platforms. Our AI generates optimized posts for Instagram, Twitter, Facebook, and LinkedIn.',
  keywords: 'social media writer, AI post generator, social content creator, social media content',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Social Media Writer',
    applicationCategory: 'Social Media Content',
    description: 'AI-powered social media content generator'
  }
},

'/landing/email-newsletter': {
  title: 'AI Email Newsletter Writer | Create Converting Newsletters',
  description: 'Write engaging email newsletters that convert. Our AI helps you create personalized, compelling email content for your subscribers.',
  keywords: 'email newsletter writer, AI email generator, newsletter content, email marketing',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Email Newsletter Writer',
    applicationCategory: 'Email Marketing',
    description: 'AI-powered email newsletter generator'
  }
},
'/landing/product-description-writer': {
  title: 'AI Product Description Generator | Powerful E-commerce Copy',
  description: 'Create compelling product descriptions that convert. Our AI writes unique, SEO-optimized product copy for all e-commerce platforms.',
  keywords: 'product description generator, AI product copy, e-commerce writing, product content creator',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Product Description Writer',
    applicationCategory: 'E-commerce Content',
    description: 'AI-powered product description generator'
  }
},

'/landing/human-writer': {
  title: 'AI Detection Bypass | Human-Like Content Generator',
  description: 'Create undetectable AI content that passes all AI detection tools. Generate human-like content while maintaining natural language flow.',
  keywords: 'AI detection bypass, human writing generator, undetectable AI content, natural writing AI',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Human Writer',
    applicationCategory: 'Content Generation',
    description: 'AI content humanizer and detection bypass tool'
  }
},

'/landing/seo-optimizer': {
  title: 'AI SEO Content Optimizer | Rank Higher on Search Engines',
  description: 'Optimize your content for search engines with AI-powered recommendations. Improve rankings and drive more organic traffic.',
  keywords: 'SEO optimizer, content optimization, search engine optimization, SEO content writer',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT SEO Optimizer',
    applicationCategory: 'SEO Tools',
    description: 'AI-powered SEO content optimization tool'
  }
},

'/landing/content-rewriter': {
  title: 'AI Content Rewriter | Unique Content Generator',
  description: 'Rewrite and refresh your content while maintaining meaning. Create unique variations of your articles, blogs, and marketing copy.',
  keywords: 'content rewriter, article spinner, text rewriting tool, content refresher',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Content Rewriter',
    applicationCategory: 'Content Creation',
    description: 'AI-powered content rewriting tool'
  }
},

'/landing/readability-improver': {
  title: 'AI Readability Optimizer | Clear & Engaging Content',
  description: 'Improve your content\'s clarity and engagement. Make complex content easy to understand for any audience.',
  keywords: 'readability improver, content clarity, writing enhancement, text simplifier',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Readability Improver',
    applicationCategory: 'Writing Tools',
    description: 'AI-powered content readability optimizer'
  }
},

'/landing/ad-copy-writer': {
  title: 'AI Ad Copy Generator | High-Converting Ad Content',
  description: 'Create compelling ad copy that converts. Generate optimized content for Google Ads, Facebook Ads, and more.',
  keywords: 'ad copy generator, PPC ad writer, advertising copy, marketing content',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Ad Copy Writer',
    applicationCategory: 'Advertising',
    description: 'AI-powered advertising copy generator'
  }
},

'/landing/landing-page-writer': {
  title: 'AI Landing Page Generator | Convert More Visitors',
  description: 'Create high-converting landing pages instantly. Our AI writes compelling copy that turns visitors into customers.',
  keywords: 'landing page generator, conversion copy, sales page writer, landing page content',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Landing Page Writer',
    applicationCategory: 'Conversion Optimization',
    description: 'AI-powered landing page content generator'
  }
},

'/landing/sales-copy-writer': {
  title: 'AI Sales Copy Generator | Persuasive Sales Content',
  description: 'Generate powerful sales copy that sells. Create persuasive content for products, services, and marketing campaigns.',
  keywords: 'sales copy generator, sales content writer, persuasive copy, marketing content',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Sales Copy Writer',
    applicationCategory: 'Sales Content',
    description: 'AI-powered sales copy generator'
  }
},

'/landing/cta-generator': {
  title: 'AI Call-to-Action Generator | Compelling CTAs',
  description: 'Create powerful calls-to-action that drive conversions. Generate CTAs for any marketing campaign or content type.',
  keywords: 'CTA generator, call to action writer, conversion copy, button text generator',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT CTA Generator',
    applicationCategory: 'Marketing Tools',
    description: 'AI-powered call-to-action generator'
  }
},

'/landing/about-page-writer': {
  title: 'AI About Page Generator | Professional Company Profiles',
  description: 'Create engaging about pages that tell your story. Generate professional company profiles and team bios.',
  keywords: 'about page generator, company profile writer, bio generator, about us content',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT About Page Writer',
    applicationCategory: 'Business Content',
    description: 'AI-powered about page generator'
  }
},

'/landing/service-page-writer': {
  title: 'AI Service Page Generator | Professional Service Descriptions',
  description: 'Create compelling service pages that convert. Generate professional descriptions for any service or offering.',
  keywords: 'service page generator, service description writer, business content, service copy',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Service Page Writer',
    applicationCategory: 'Business Content',
    description: 'AI-powered service page generator'
  }
},

'/landing/faq-generator': {
  title: 'AI FAQ Generator | Comprehensive Q&A Content',
  description: 'Generate comprehensive FAQ sections instantly. Create relevant questions and answers for any topic or service.',
  keywords: 'FAQ generator, Q&A content, help content writer, support content',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT FAQ Generator',
    applicationCategory: 'Content Creation',
    description: 'AI-powered FAQ content generator'
  }
},

'/landing/features-page-writer': {
  title: 'AI Features Page Generator | Product Feature Content',
  description: 'Create compelling feature pages that showcase your product. Generate clear, benefit-focused feature descriptions.',
  keywords: 'features page generator, product features writer, feature content, product description',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Features Page Writer',
    applicationCategory: 'Product Content',
    description: 'AI-powered features page generator'
  }
},

'/landing/image-generator': {
  title: 'AI Image Generator | Create Custom Images (Coming Soon)',
  description: 'Generate custom images for your content. Create unique visuals for blogs, social media, and marketing materials.',
  keywords: 'AI image generator, custom image creator, visual content, marketing images',
  jsonLd: {
    '@type': 'SoftwareApplication',
    name: 'The Content GPT Image Generator',
    applicationCategory: 'Visual Content',
    description: 'AI-powered image generation tool (Coming Soon)'
  }
},
// Dynamic blog post route pattern
'blog-post': {
  getMetadata: (post) => ({
    title: `${post.title} | The Content GPT Blog`,
    description: post.excerpt || post.seoDescription,
    keywords: post.tags.join(', '),
    robots: 'index, follow',
    jsonLd: {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      headline: post.title,
      description: post.excerpt,
      image: post.featuredImage,
      author: {
        '@type': 'Person',
        name: post.author?.name || 'The Content GPT Team'
      },
      publisher: {
        '@type': 'Organization',
        name: 'The Content GPT',
        logo: {
          '@type': 'ImageObject',
          url: defaultMetadata.logoUrl
        }
      },
      datePublished: post.publishDate,
      dateModified: post.updatedAt,
      keywords: post.tags.join(','),
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${defaultMetadata.baseUrl}/blog/${post.slug}`
      }
    }
  })
}
};
  // Add more route-specific metadata as needed


  const SEOHead = ({ 
    title,
    description,
    keywords,
    image,
    article = false,
    publishDate,
    modifiedDate,
    author,
    noindex = false,
    post = null // Add post parameter for blog posts
  }) => {
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    const currentUrl = `${defaultMetadata.baseUrl}${path}`;
  
    // Handle dynamic blog post metadata
  let metaData;
  if (post && path.startsWith('/blog/')) {
    metaData = routeMetadata['blog-post'].getMetadata(post);
  } else {
    metaData = routeMetadata[path] || {};
  }

  const finalTitle = title || metaData.title || defaultMetadata.defaultTitle;
  const finalDescription = description || metaData.description || defaultMetadata.defaultDescription;
  const finalKeywords = keywords || metaData.keywords || defaultMetadata.defaultKeywords;
  const finalImage = image || defaultMetadata.defaultImage;
  const robotsContent = metaData.robots || (noindex ? 'noindex, nofollow' : 'index, follow');


  // Get metadata for current route, fallback to defaults
  const routeData = routeMetadata[path] || {};


  // Schema.org structured data
  const baseSchemaOrg = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: defaultMetadata.siteName,
    url: defaultMetadata.baseUrl,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${defaultMetadata.baseUrl}/search?q={search_term_string}`
      },
      'query-input': 'required name=search_term_string'
    }
  };

  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: defaultMetadata.siteName,
    url: defaultMetadata.baseUrl,
    logo: defaultMetadata.logoUrl,
    sameAs: [
      'https://twitter.com/contentgpt',
      'https://www.linkedin.com/company/contentgpt',
      'https://www.facebook.com/contentgpt'
    ]
  };

  const articleSchema = article ? {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: finalTitle,
    description: finalDescription,
    image: finalImage,
    datePublished: publishDate,
    dateModified: modifiedDate || publishDate,
    author: {
      '@type': 'Person',
      name: author || defaultMetadata.siteName
    },
    publisher: {
      '@type': 'Organization',
      name: defaultMetadata.siteName,
      logo: {
        '@type': 'ImageObject',
        url: defaultMetadata.logoUrl
      }
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': currentUrl
    }
  } : null;

  // Route-specific schema
  const routeSchema = routeData.jsonLd ? {
    '@context': 'https://schema.org',
    ...routeData.jsonLd
  } : null;

  return (
    <Helmet>
      {/* Preload Critical Assets */}
      <link 
        rel="preload" 
        href="/fonts/inter-var-latin.woff2" 
        as="font" 
        type="font/woff2" 
        crossOrigin="anonymous" 
      />
      
      {/* Preload Critical CSS */}
      <link 
        rel="preload"
        href="/css/critical.css"
        as="style"
      />

      {/* Performance Optimizations */}
      <style>{`
        @font-face {
          font-family: 'Inter';
          font-weight: 100 900;
          font-display: optional;
          src: url('/fonts/inter-var-latin.woff2') format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6;
        }
      `}</style>

      {/* Resource Hints */}
      <link rel="dns-prefetch" href="https://thecontentgpt.com" />
      {/* Basic Meta Tags */}
      <title>{finalTitle}</title>
      <meta name="description" content={finalDescription} />
      <meta name="keywords" content={finalKeywords} />
      <link rel="canonical" href={currentUrl} />
      
      {/* OpenGraph Meta Tags */}
      <meta property="og:type" content={article ? 'article' : 'website'} />
      <meta property="og:site_name" content={defaultMetadata.siteName} />
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={finalDescription} />
      <meta property="og:image" content={finalImage} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:locale" content="en_US" />
      
      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={defaultMetadata.twitterHandle} />
      <meta name="twitter:creator" content={defaultMetadata.twitterHandle} />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={finalDescription} />
      <meta name="twitter:image" content={finalImage} />
      
      {/* Enhanced Favicon Tags */}
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon.ico" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon.ico" />
      <link rel="manifest" href="/site.webmanifest" />
      
      {/* Force favicon refresh */}
      <link rel="icon" type="image/x-icon" href={`/favicon.ico?v=${new Date().getTime()}`} />
      
      {/* Web App Manifest */}
      <link rel="manifest" href="/manifest.json" />
      
      {/* Organization Schema with Logo */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          'url': defaultMetadata.baseUrl,
          'logo': `${defaultMetadata.baseUrl}/logo512.png`,
          'image': `${defaultMetadata.baseUrl}/logo512.png`,
          'name': defaultMetadata.siteName
        })}
      </script>
      {/* Article Meta Tags */}
      {article && publishDate && (
        <>
          <meta property="article:published_time" content={publishDate} />
          {modifiedDate && <meta property="article:modified_time" content={modifiedDate} />}
          {author && <meta property="article:author" content={author} />}
        </>
      )}
      
      {/* Technical Meta Tags */}
      <meta name="robots" content={noindex ? 'noindex, nofollow' : 'index, follow'} />
      <meta name="googlebot" content={noindex ? 'noindex, nofollow' : 'index, follow'} />
      <meta name="theme-color" content={defaultMetadata.themeColor} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      
      {/* Alternate Language Tags */}
      {defaultMetadata.alternateLanguages.map(({ hrefLang, href }) => (
        <link key={hrefLang} rel="alternate" hrefLang={hrefLang} href={href} />
      ))}
      
      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(baseSchemaOrg)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      {article && (
        <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
        </script>
      )}
      {routeSchema && (
        <script type="application/ld+json">
          {JSON.stringify(routeSchema)}
        </script>
      )}
    </Helmet>
  );
};
};

export default SEOHead;
