import React, { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import SEOHead from '../components/SEOHead';
import { 
  ArrowUpRight, 
  Package, 
  Zap,
  RefreshCw,
  AlertCircle,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { Card } from '../components/ui/card';
import { Button } from '../components/ui/button';
import PaymentForm from '../components/PaymentForm';

const ITEMS_PER_PAGE = 8;

export default function PointsManagement() {
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [pointsData, setPointsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchPointsData = async () => {
      try {
        setLoading(true);
        // Updated endpoint path to include /api/
        const data = await apiService.fetch('/api/user/points');
        
        if (data.history) {
          data.history.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        }
        setPointsData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPointsData();
    const pollInterval = setInterval(fetchPointsData, 30000);
    return () => clearInterval(pollInterval);
  }, []);

  const TransactionIcon = ({ type }) => {
    switch (type) {
      case 'usage':
        return <Zap className="h-5 w-5 text-orange-500 dark:text-orange-400" />;
      case 'subscription_bonus':
      case 'subscription_renewal':
        return <RefreshCw className="h-5 w-5 text-green-500 dark:text-green-400" />;
      case 'purchase':
        return <ArrowUpRight className="h-5 w-5 text-green-500 dark:text-green-400" />;
      default:
        return <AlertCircle className="h-5 w-5 text-gray-500 dark:text-gray-400" />;
    }
  };

  const formatTransactionDescription = (transaction) => {
    switch (transaction.type) {
      case 'usage':
        return `Used ${transaction.wordsGenerated?.toLocaleString() || 0} words for ${transaction.feature || 'content generation'}`;
      case 'subscription_bonus':
        return transaction.description || 'Subscription bonus points';
      case 'subscription_renewal':
        return transaction.description || 'Monthly subscription points';
      case 'purchase':
        return transaction.description || 'Points purchase';
      default:
        return transaction.description || 'Points transaction';
    }
  };

  const calculateStats = () => {
    if (!pointsData?.history) return { totalPointsUsed: 0, totalWordsGenerated: 0 };
    
    return pointsData.history.reduce((acc, transaction) => {
      if (transaction.type === 'usage') {
        acc.totalPointsUsed += Math.abs(transaction.amount);
      }
      if (transaction.wordsGenerated) {
        acc.totalWordsGenerated += transaction.wordsGenerated;
      }
      return acc;
    }, { totalPointsUsed: 0, totalWordsGenerated: 0 });
  };

  const totalPages = pointsData?.history 
    ? Math.ceil(pointsData.history.length / ITEMS_PER_PAGE) 
    : 0;

  const paginatedTransactions = pointsData?.history
    ? pointsData.history.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
      )
    : [];

  const Pagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex items-center justify-center space-x-2 mt-6">
        <Button
          variant="outline"
          size="icon"
          onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
          className="dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>

        {pageNumbers.map(number => (
          <Button
            key={number}
            variant={currentPage === number ? "default" : "outline"}
            onClick={() => setCurrentPage(number)}
            className={`w-8 h-8 p-0 ${
              currentPage === number 
                ? 'dark:bg-purple-600' 
                : 'dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300'
            }`}
          >
            {number}
          </Button>
        ))}

        <Button
          variant="outline"
          size="icon"
          onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
          disabled={currentPage === totalPages}
          className="dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/4"></div>
            <div className="h-32 bg-gray-200 dark:bg-gray-700 rounded"></div>
            <div className="h-64 bg-gray-200 dark:bg-gray-700 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <Card className="p-6 border-red-200 dark:border-red-800 bg-red-50 dark:bg-red-900/50">
            <div className="flex items-center text-red-800 dark:text-red-200">
              <AlertCircle className="h-5 w-5 mr-2" />
              <h3 className="font-medium">Error loading points data</h3>
            </div>
            <p className="mt-2 text-sm text-red-700 dark:text-red-300">{error}</p>
          </Card>
        </div>
      </div>
    );
  }

  const stats = calculateStats();

  
  return (
    <>
      <SEOHead
  noindex={true}
/>
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
      <div className="max-w-7xl mx-auto px-4">
        {/* Points Overview */}
        <Card className="p-6 mb-8 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Points Balance</h1>
          <div className="flex items-baseline">
            <span className="text-4xl font-bold text-purple-600 dark:text-purple-400">
              {pointsData?.balance?.toLocaleString() || 0}
            </span>
            <span className="ml-2 text-gray-500 dark:text-gray-400">available points</span>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
            <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
              <div className="text-sm text-gray-500 dark:text-gray-400">Total Points Used</div>
              <div className="text-xl font-semibold text-gray-900 dark:text-white">
                {stats.totalPointsUsed.toLocaleString()}
              </div>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
              <div className="text-sm text-gray-500 dark:text-gray-400">Words Generated</div>
              <div className="text-xl font-semibold text-gray-900 dark:text-white">
                {stats.totalWordsGenerated.toLocaleString()}
              </div>
            </div>
          </div>

          {pointsData?.subscription && (
            <div className="mt-6 p-4 bg-purple-50 dark:bg-purple-900/50 rounded-lg">
              <div className="flex items-center text-purple-700 dark:text-purple-300">
                <Package className="h-5 w-5 mr-2" />
                <span className="font-medium">
                  Current Plan: {pointsData.subscription.planName}
                </span>
              </div>
              <div className="mt-2 text-sm text-purple-600 dark:text-purple-400">
                Next billing date: {new Date(pointsData.subscription.nextBillingDate).toLocaleDateString()}
              </div>
            </div>
          )}
        </Card>

        {/* Transaction History */}
        <Card className="p-6 mb-8 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Transaction History</h2>
          <div className="space-y-4">
            {paginatedTransactions.length === 0 ? (
              <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                No transactions yet
              </div>
            ) : (
              paginatedTransactions.map((transaction, index) => (
                <div key={index} className="flex items-center justify-between border-b border-gray-100 dark:border-gray-700 pb-4">
                  <div className="flex items-center">
                    <div className="mr-3">
                      <TransactionIcon type={transaction.type} />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {formatTransactionDescription(transaction)}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {new Date(transaction.timestamp).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <span className={`font-medium ${
                    transaction.type === 'usage' 
                      ? 'text-red-600 dark:text-red-400' 
                      : 'text-green-600 dark:text-green-400'
                  }`}>
                    {transaction.type === 'usage' ? '-' : '+'}
                    {Math.abs(transaction.amount).toLocaleString()}
                  </span>
                </div>
              ))
            )}
          </div>
          <Pagination />
        </Card>

        {/* Subscription Plans */}
        <Card className="p-6 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Available Plans</h2>
          
          {/* Plan Toggle */}
          <div className="mb-8 flex justify-center">
            <div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-1 shadow-sm inline-flex">
              <button 
                className={`px-6 py-2 rounded-md transition-colors ${
                  selectedPlan === 'monthly' 
                    ? 'bg-purple-600 text-white' 
                    : 'text-gray-600 dark:text-gray-300'
                }`}
                onClick={() => setSelectedPlan('monthly')}
              >
                Monthly
              </button>
              <button 
                className={`px-6 py-2 rounded-md flex items-center space-x-2 transition-colors ${
                  selectedPlan === 'yearly' 
                    ? 'bg-purple-600 text-white' 
                    : 'text-gray-600 dark:text-gray-300'
                }`}
                onClick={() => setSelectedPlan('yearly')}
              >
                <span>Yearly</span>
                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200">
                  20% OFF
                </span>
              </button>
            </div>
          </div>

          {/* Plan Cards */}
          <div className="grid md:grid-cols-2 gap-6">
            {/* STARTER Plan */}
            <Card className="p-6 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
              <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">STARTER</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                  Perfect for individual content creators
                </p>
                <div className="mt-4">
                  <div className="flex items-baseline">
                    <span className="text-3xl font-bold text-gray-900 dark:text-white">
                      ${selectedPlan === 'yearly' ? '15.99' : '19.99'}
                    </span>
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 ml-1">/month</span>
                  </div>
                  {selectedPlan === 'yearly' && (
                    <div className="mt-1">
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        Billed yearly (${190}/year)
                      </span>
                      <div className="mt-1">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200">
                          Save 20%
                        </span>
                      </div>
                    </div>
                  )}
                  <ul className="mt-4 space-y-3">
                    <li className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                      <span className="mr-2">•</span>
                      70,000 points monthly
                    </li>
                    <li className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                      <span className="mr-2">•</span>
                      Priority email support
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-auto">
                <PaymentForm 
                  plan={{
                    name: 'STARTER',
                    points: 70000,
                    price: { monthly: 19.99, yearly: 189.99 }
                  }}
                  billingCycle={selectedPlan}
                />
              </div>
            </Card>

            {/* PRO Plan */}
            <Card className="p-6 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
              <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">PRO</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                  Best for growing businesses
                </p>
                <div className="mt-4">
                  <div className="flex items-baseline">
                    <span className="text-3xl font-bold text-gray-900 dark:text-white">
                      ${selectedPlan === 'yearly' ? '59.99' : '79.99'}
                    </span>
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 ml-1">/month</span>
                  </div>
                  {selectedPlan === 'yearly' && (
                    <div className="mt-1">
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        Billed yearly (${720}/year)
                      </span>
                      <div className="mt-1">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200">
                          Save 25%
                        </span>
                      </div>
                    </div>
                  )}
                  <ul className="mt-4 space-y-3">
                    <li className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                      <span className="mr-2">•</span>
                      350,000 points monthly
                    </li>
                    <li className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                      <span className="mr-2">•</span>
                      Priority email support
                    </li>
                    <li className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                      <span className="mr-2">•</span>
                      Advanced features
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-auto">
                <PaymentForm 
                  plan={{
                    name: 'PRO',
                    points: 350000,
                    price: { monthly: 79.99, yearly: 719.99 }
                  }}
                  billingCycle={selectedPlan}
                />
              </div>
            </Card>
          </div>
        </Card>
      </div>
    </div>
    </>
  );
}