import React, { useRef } from 'react';
import { Network, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const correlationConfig = {
  id: 'correlation',
  label: 'Correlation Analysis',
  icon: <Network className="w-4 h-4" />,
  description: 'Analyze relationships between variables',
};

export const createPrompt = (data, columns) => {
  // Filter for numeric columns only since correlation only works with numbers
  const numericColumns = columns.filter(col => {
    const sampleValue = data[0]?.[col.accessor || col.id];
    return typeof sampleValue === 'number';
  });

  const tablePreview = data.map(row => {
    return columns.map(col => `${col.Header || col.id}: ${row[col.accessor || col.id]}`).join(', ');
  }).join('\n');
  
  // Create a clean data representation with just the numeric values
  const cleanData = data.map(row => {
    const numericData = {};
    numericColumns.forEach(col => {
      const accessor = col.accessor || col.id;
      numericData[col.Header || col.id] = row[accessor];
    });
    return numericData;
  });
// Include full table data preview
const fullTablePreview = data.map(row => {
  return columns.map(col => {
    const accessor = col.accessor || col.id;
    const header = col.Header || col.id;
    return `${header}: ${row[accessor]}`;
  }).join(', ');
}).join('\n');

const prompt = `Analyze the correlations between numeric variables in this dataset and provide analysis in this exact JSON format:

{
  "correlationMatrix": {
    "variables": ["var1", "var2", ...],
    "matrix": [
      [1, 0.5, ...],
      [0.5, 1, ...],
      ...
    ]
  },
  "strongCorrelations": [
    {
      "variables": ["var1", "var2"],
      "correlation": number,
      "interpretation": "description of relationship"
    }
  ],
  "weakCorrelations": [
    {
      "variables": ["var1", "var2"],
      "correlation": number,
      "interpretation": "description of relationship"
    }
  ],
  "scatterPlotData": {
    "recommendedPairs": [
      {
        "x": "variable1",
        "y": "variable2",
        "data": [
          {"x": number, "y": number},
          ...
        ]
      }
    ]
  },
  "insights": [
    "key insight 1",
    "key insight 2"
  ]
}

Complete Table Data:
${fullTablePreview}

Numeric Data for Correlation:
${JSON.stringify(cleanData, null, 2)}

Available Numeric Columns:
${numericColumns.map(col => col.Header || col.id).join(', ')}

Please provide detailed interpretations for correlations and focus on meaningful relationships in the data.`;


  return prompt;
};

export const parseResults = (apiResponse) => {
  try {
    const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
    
    if (!jsonMatch || !jsonMatch[1]) {
      console.error('No JSON content found in markdown blocks');
      throw new Error('Invalid API response format');
    }

    const parsedJson = JSON.parse(jsonMatch[1]);
    

    return {
      correlationMatrix: parsedJson.correlationMatrix || { variables: [], matrix: [] },
      strongCorrelations: parsedJson.strongCorrelations || [],
      weakCorrelations: parsedJson.weakCorrelations || [],
      insights: parsedJson.insights || []
    };

  } catch (error) {
    console.error('Error while parsing results:', error);
    return {
      correlationMatrix: { variables: [], matrix: [] },
      strongCorrelations: [],
      weakCorrelations: [],
      insights: ['Failed to parse analysis results: ' + error.message]
    };
  }
};

const getCorrelationColor = (value) => {
  // Convert correlation value to color
  const absValue = Math.abs(value);
  if (absValue >= 0.8) return 'bg-red-600 text-white';
  if (absValue >= 0.6) return 'bg-red-400 text-white';
  if (absValue >= 0.4) return 'bg-red-300 text-gray-800';
  if (absValue >= 0.2) return 'bg-red-200 text-gray-800';
  return 'bg-red-100 text-gray-800';
};

export const CorrelationResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const { correlationMatrix, strongCorrelations, weakCorrelations, insights } = results;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('correlation-analysis.pdf');
  };

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef}>
      {/* Correlation Matrix */}
<div className="bg-white dark:bg-gray-800 p-4 rounded-lg overflow-x-auto">
  <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">Correlation Matrix</h4>
  <div className="min-w-max">
    <table className="w-full border-collapse">
      <thead>
        <tr>
          <th className="p-2 border dark:border-gray-600 text-gray-900 dark:text-gray-100"></th>
          {correlationMatrix.variables.map((variable) => (
            <th key={variable} className="p-2 border dark:border-gray-600 text-sm font-medium text-gray-900 dark:text-gray-100">
              {variable}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {correlationMatrix.matrix.map((row, i) => (
          <tr key={i}>
            <th className="p-2 border dark:border-gray-600 text-sm font-medium text-left text-gray-900 dark:text-gray-100">
              {correlationMatrix.variables[i]}
            </th>
            {row.map((value, j) => (
              <td 
                key={j} 
                className={`p-2 border dark:border-gray-600 text-center ${getCorrelationColor(value)}`}
              >
                {value.toFixed(2)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

      {/* Strong Correlations */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
          <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">Strong Correlations</h4>
          <div className="space-y-4">
            {strongCorrelations.map((correlation, index) => (
              <div key={index} className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {correlation.variables.join(' & ')}
                  </span>
                  <span className="font-medium text-green-600 dark:text-green-400">
                    r = {correlation.correlation.toFixed(3)}
                  </span>
                </div>
                <p className="text-gray-700 dark:text-gray-300 text-sm">
                  {correlation.interpretation}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Weak Correlations */}
        <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
          <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">Weak Correlations</h4>
          <div className="space-y-4">
            {weakCorrelations.map((correlation, index) => (
              <div key={index} className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {correlation.variables.join(' & ')}
                  </span>
                  <span className="font-medium text-yellow-600 dark:text-yellow-400">
                    r = {correlation.correlation.toFixed(3)}
                  </span>
                </div>
                <p className="text-gray-700 dark:text-gray-300 text-sm">
                  {correlation.interpretation}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Insights */}
      {insights?.length > 0 && (
        <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6">
          <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">Key Insights</h4>
          <ul className="space-y-3">
            {insights.map((insight, index) => (
              <li 
                key={index}
                className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
              >
                <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 font-medium">
                  {index + 1}
                </span>
                <span>{insight}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      </div>
    </div>
  );
};

export default {
  config: correlationConfig,
  createPrompt,
  parseResults,
  ResultsComponent: CorrelationResults
};