import React, { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { 
  Wand2, 
  Copy, 
  CheckCircle2,
  AlertCircle,
  Sparkles,
  Shield,
  Zap,
  Brain,
  Target,
  Lock,
  ChevronDown
} from 'lucide-react';

const HumanWriter = () => {
  // State definitions
  const [formData, setFormData] = useState({
    title: '',
    length: '500',
    outline: ''
  });
  const [userPoints, setUserPoints] = useState(0);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [generatedContent, setGeneratedContent] = useState({
    text: '',
    formattedText: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [error, setError] = useState('');
  const [openFaq, setOpenFaq] = useState(null);
  const [templateConfig, setTemplateConfig] = useState(null);

  // Format content helper function
  const formatContent = (text) => {
    if (!text) return '';
    
    // Format bold text (enclosed in **)
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Format headings (lines starting with # or ##)
    formattedText = formattedText.replace(/^# (.*?)$/gm, '<h1 class="text-2xl font-bold my-4">$1</h1>');
    formattedText = formattedText.replace(/^## (.*?)$/gm, '<h2 class="text-xl font-bold my-3">$1</h2>');
    formattedText = formattedText.replace(/^### (.*?)$/gm, '<h3 class="text-lg font-bold my-2">$1</h3>');
    
    // Convert newlines to <br /> tags
    formattedText = formattedText.replace(/\n/g, '<br />');
    
    return formattedText;
  };
  
  // Replace the template loading useEffect in HumanWriter.js
// Update the template loading useEffect
useEffect(() => {
  const loadTemplates = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      const response = await fetch(apiService.getUrl('/api/templates'), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-store'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch templates');
      }

      const templates = await response.json();
      
      if (templates['human-writer']) {
        try {
          const config = JSON.parse(templates['human-writer'].pythonCode);
          setTemplateConfig({
            ...templates['human-writer'],
            promptConfig: config
          });
        } catch (error) {
          console.error('Error parsing template config:', error);
          setError('Failed to load template configuration');
        }
      }
    } catch (error) {
      console.error('Error loading template:', error);
      setError('Failed to load template configuration');
    }
  };

  loadTemplates();
}, []);

  // Points Loading
  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(apiService.getUrl('/api/points'), {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setUserPoints(data.balance);
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    };
  
    fetchPoints();
  }, []);

  // Input Handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (error) setError('');

    // Calculate points based on length field
    if (name === 'length') {
      const wordCount = parseInt(value) || 0;
      const pointsPerWord = templateConfig?.pointsCost || 2;
      const cost = wordCount * pointsPerWord;
      setEstimatedCost(cost);
    }
  };

  // Copy Handler
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(generatedContent.text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      setError('Failed to copy to clipboard');
    }
  };

  const handleGenerate = async () => {
    if (!formData.title.trim()) {
      setError('Please enter an article title');
      return;
    }
  
    const wordCount = formData.length === 'custom' 
      ? parseInt(formData.customLength) 
      : parseInt(formData.length);
  
    if (formData.length === 'custom' && (!wordCount || wordCount < 500)) {
      setError('Please enter at least 500 words for custom length');
      return;
    }
  
    setIsProcessing(true);
    setError('');
    setGeneratedContent({ text: '', formattedText: '' });
  
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Please log in to use this feature');
      }
  
      const targetWordCount = formData.length === 'custom' 
        ? parseInt(formData.customLength) 
        : parseInt(formData.length);
      const pointsPerWord = templateConfig?.pointsCost || 2;
      const pointsToDeduct = targetWordCount * pointsPerWord;
  
      const pointsResponse = await fetch(apiService.getUrl('/api/points'), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!pointsResponse.ok) {
        throw new Error('Failed to check points balance');
      }
  
      const pointsData = await pointsResponse.json();
      if (pointsData.balance < pointsToDeduct) {
        throw new Error(`Insufficient points. You need ${pointsToDeduct} points. Current balance: ${pointsData.balance}`);
      }
  
      let requestBody;
      try {
        if (templateConfig?.promptConfig) {
          // Use the new format
          const config = templateConfig.promptConfig;
          
          // Create a deep copy of the config
          const promptData = JSON.parse(JSON.stringify(config));
          
          // Replace all placeholders in the messages
          promptData.messages = promptData.messages.map(msg => ({
            ...msg,
            content: msg.content.map(contentBlock => ({
              ...contentBlock,
              text: contentBlock.text
                .replace(/{length}/g, targetWordCount.toString())
                .replace(/{topic}/g, formData.title)
                .replace(/{purpose}/g, formData.purpose || 'Informative')
                .replace(/{targetAudience}/g, formData.targetAudience || 'General audience')
                .replace(/{keyPoints}/g, formData.outline || 'None provided')
                .replace(/{style}/g, formData.style || 'Professional')
                .replace(/{tone}/g, formData.tone || 'Neutral')
            }))
          }));
  
          requestBody = {
            ...promptData,
            metadata: {
              input_word_count: targetWordCount,
              points_to_deduct: pointsToDeduct,
              feature: "human-writer",
              identifier: "human-writer"
            }
          };
        } else {
          throw new Error('Template configuration not found');
        }
      } catch (error) {
        console.error('Try Again', error);
        throw new Error('Try Again');
      }
  
      const response = await fetch(apiService.getUrl('/api/proxy/anthropic/stream'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody)
      });

      
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || 'Failed to generate content');
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedText = '';
  
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data.trim() === '[DONE]') continue;
            
            try {
              const parsed = JSON.parse(data);
              if (parsed.type === 'content_block_delta' && parsed.delta?.text) {
                accumulatedText += parsed.delta.text;
                setGeneratedContent({
                  text: accumulatedText,
                  formattedText: formatContent(accumulatedText)
                });
              } else if (parsed.type === 'points_update') {
                setUserPoints(parsed.remaining_balance);
                window.dispatchEvent(new CustomEvent('pointsUpdate', { 
                  detail: { balance: parsed.remaining_balance }
                }));
              }
            } catch (e) {
              console.error('Error parsing stream data:', e);
            }
          }
        }
      }
  
    } catch (err) {
      console.error('Generation error:', err);
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // JSX for the component
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white dark:from-gray-900 dark:to-gray-800">
      {/* Premium Feature Banner */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-2">
            <Lock className="h-4 w-4" />
            <span>Premium Feature: Generate Human-Like Article Content</span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-16">
          {/* Input Form */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Article Details</h2>
              
              {/* Title Input */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Article Title
                </label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  placeholder="Enter your article title..."
                  className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500"
                />
              </div>

              {/* Word Count Input */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Word Count
                </label>
                <div className="flex items-center space-x-2">
                  <select
                    name="length"
                    value={formData.length}
                    onChange={handleInputChange}
                    className="flex-1 p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  >
                    <option value="500">500 words</option>
                    <option value="750">750 words</option>
                    <option value="1000">1000 words</option>
                    <option value="1500">1500 words</option>
                    <option value="2000">2000 words</option>
                    <option value="2500">2500 words</option>
                    <option value="custom">Custom Length</option>
                  </select>
                  {formData.length === 'custom' && (
                    <div className="relative w-32">
                      <input
                        type="number"
                        name="customLength"
                        value={formData.customLength || ''}
                        onChange={handleInputChange}
                        min="500"
                        placeholder="Min: 500"
                        className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white ${
                          formData.customLength && formData.customLength < 500 
                            ? 'border-red-300 dark:border-red-500' 
                            : 'border-gray-300 dark:border-gray-600'
                        }`}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Outline Input */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Article Outline (Optional)
                </label>
                <textarea
                  name="outline"
                  value={formData.outline}
                  onChange={handleInputChange}
                  placeholder="(OPTIONAL) ONLY ADD IF NECESSARY--MIGHT MAKE THE CONTENT AI..."
                  rows={4}
                  className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent resize-none bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500"
                />
              </div>
              
              {/* Points Info Display */}
              <div className="mt-6 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                <div className="space-y-2">
                  <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-600 dark:text-gray-300">Points per Word:</span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {templateConfig?.pointsCost || 2}
                    </span>
                  </div>
                  <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-600 dark:text-gray-300">Estimated Cost:</span>
                    <span className="font-medium text-purple-600 dark:text-purple-400">
                      {estimatedCost} points
                    </span>
                  </div>
                  <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-600 dark:text-gray-300">Your Balance:</span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {userPoints} points
                    </span>
                  </div>
                  <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-600 dark:text-gray-300">Remaining After:</span>
                    <span className={`font-medium ${
                      userPoints - estimatedCost < 0 
                        ? 'text-red-600 dark:text-red-400' 
                        : 'text-green-600 dark:text-green-400'
                    }`}>
                      {userPoints - estimatedCost} points
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {error && (
              <div className="mb-4 flex items-center text-red-600 dark:text-red-400">
                <AlertCircle className="h-5 w-5 mr-2" />
                {error}
              </div>
            )}

            <button
              onClick={handleGenerate}
              disabled={isProcessing || !formData.title || !formData.length}
              className={`w-full flex items-center justify-center px-4 py-3 rounded-lg text-white font-medium ${
                isProcessing || !formData.title || !formData.length
                  ? 'bg-purple-400 dark:bg-purple-500 cursor-not-allowed'
                  : 'bg-purple-600 hover:bg-purple-700 dark:bg-purple-600 dark:hover:bg-purple-700'
              }`}
            >
              {isProcessing ? (
                <>
                  <Sparkles className="animate-spin h-5 w-5 mr-2" />
                  Writing Article...
                </>
              ) : (
                <>
                  <Wand2 className="h-5 w-5 mr-2" />
                  Generate Article
                </>
              )}
            </button>
          </div>

          {/* Output */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-bold text-gray-900 dark:text-white">Generated Article</h2>
              {generatedContent.text && (
                <button
                  onClick={handleCopy}
                  className="text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300"
                  title="Copy to clipboard"
                >
                  {copySuccess ? (
                    <CheckCircle2 className="h-5 w-5 text-green-500 dark:text-green-400" />
                  ) : (
                    <Copy className="h-5 w-5" />
                  )}
                </button>
              )}
            </div>

            <div className="relative min-h-[600px]">
    {generatedContent.text ? (
      <div className="absolute inset-0 overflow-y-auto p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
        <div 
          className="prose dark:prose-invert max-w-none text-gray-900 dark:text-gray-100"
          dangerouslySetInnerHTML={{ 
            __html: generatedContent.formattedText 
          }}
        />
      </div>
    ) : (
                <div className="absolute inset-0 flex items-center justify-center text-center text-gray-500 dark:text-gray-400 border border-gray-200 dark:border-gray-700 rounded-lg">
                  <div>
                    <Wand2 className="h-12 w-12 mx-auto mb-4 opacity-50" />
                    <p>Your article will appear here</p>
                    <p className="text-sm">Fill in the details and click "Generate Article"</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
            <Shield className="h-12 w-12 text-purple-600 dark:text-purple-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">100% Human-Like</h3>
            <p className="text-gray-600 dark:text-gray-400">Advanced writing that produces natural, engaging articles indistinguishable from human writing.</p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
            <Target className="h-12 w-12 text-purple-600 dark:text-purple-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">SEO Ready</h3>
            <p className="text-gray-600 dark:text-gray-400">Optimized structure and readability that helps your articles rank higher in search results.</p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
            <Brain className="h-12 w-12 text-purple-600 dark:text-purple-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">Smart Context</h3>
            <p className="text-gray-600 dark:text-gray-400">Understands your topic deeply and creates comprehensive, well-researched articles.</p>
          </div>
        </div>

        {/* Premium Feature Pricing */}
        <div className="bg-gradient-to-r from-purple-600 to-indigo-600 rounded-xl shadow-lg p-8 text-white mb-16">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Premium Feature Pricing</h2>
            <p className="text-xl mb-8">Our Human Article Writer uses 2x points per word for enhanced processing</p>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="bg-white/10 rounded-lg p-6">
                <h3 className="font-bold mb-2">Example Cost</h3>
                <p>1000-word article = 2,000 points</p>
              </div>
              <div className="bg-white/10 rounded-lg p-6">
                <h3 className="font-bold mb-2">Quality Guarantee</h3>
                <p>100% Human-Like Content</p>
              </div>
              <div className="bg-white/10 rounded-lg p-6">
                <h3 className="font-bold mb-2">Time Saved</h3>
                <p>Write Articles in Minutes</p>
              </div>
            </div>
          </div>
        </div>

        {/* FAQs */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-8">
          <h2 className="text-2xl font-bold mb-8 text-center text-gray-900 dark:text-white">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200 dark:border-gray-700 last:border-0">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-gray-900 dark:text-white">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 dark:text-gray-400 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600 dark:text-gray-400">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Keep your existing faqs data here
const faqs = [
  {
    question: "Why does the Human Article Writer use 2x points?",
    answer: "This premium feature uses advanced processing to ensure your articles are not only high-quality but also indistinguishable from human writing. The 2x points cost reflects the enhanced processing needed for superior results."
  },
  {
    question: "How long can my articles be?",
    answer: "You can generate articles of any length using our predefined options from 500 to 2500 words, with the points cost being 2 points per word. For example, a 1000-word article will cost 2,000 points."
  },
  {
    question: "Is the outline really optional?",
    answer: "Yes! While providing an outline helps guide the article structure and ensures specific points are covered, our AI can generate well-structured articles from just a title and desired length."
  },
  {
    question: "What topics can I write about?",
    answer: "Our Human Article Writer can handle any topic, from technical subjects to creative pieces. It's particularly effective for blog posts, academic articles, business content, and industry analysis."
  },
  {
    question: "How does the quality compare to human writing?",
    answer: "The content is designed to match human writing patterns in terms of style, flow, and depth. It includes natural transitions, varied sentence structures, and contextually appropriate examples."
  }
];
export default HumanWriter;