import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, AlertDescription } from './Alert';
import { triggerConfetti } from '../utils/confetti';
import { CheckCircle, AlertCircle } from 'lucide-react';

const PaymentSuccessModal = ({ onClose }) => (
  <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
    <div className="bg-white dark:bg-gray-800 max-w-md w-full mx-4 rounded-lg shadow-xl p-6 animate-slide-up">
      <div className="text-center">
        <div className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-green-100 dark:bg-green-900/20 mb-6">
          <CheckCircle className="h-8 w-8 text-green-600 dark:text-green-400" />
        </div>
        
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
          Thank You for Your Purchase!
        </h2>
        
        <Alert className="mb-6 dark:green-900/20">
          <AlertTitle>Please Note:</AlertTitle>
          <AlertDescription>
            Points will be added to your account within 5-10 minutes. If you don't see your points after this time, please contact support@thecontentgpt.com
          </AlertDescription>
        </Alert>

        <button
          onClick={onClose}
          className="w-full py-3 px-4 bg-purple-600 hover:bg-purple-700 text-white font-medium rounded-lg transition-colors"
        >
          Got it, thanks!
        </button>
      </div>
    </div>
  </div>
);

const PaymentCancelModal = ({ onClose, onRetry }) => (
  <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
    <div className="bg-white dark:bg-gray-800 max-w-md w-full mx-4 rounded-lg shadow-xl p-6 animate-slide-up">
      <div className="text-center">
        <div className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-yellow-100 dark:bg-yellow-900/20 mb-6">
          <AlertCircle className="h-8 w-8 text-yellow-600 dark:text-yellow-400" />
        </div>
        
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
          Payment Incomplete
        </h2>
        
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          We noticed you didn't complete your payment. Our premium features can help you create better content faster. Would you like to reconsider your subscription?
        </p>

        <div className="space-y-3">
          <button
            onClick={onRetry}
            className="w-full py-3 px-4 bg-purple-600 hover:bg-purple-700 text-white font-medium rounded-lg transition-colors"
          >
            Yes, I'd like to continue
          </button>
          
          <button
            onClick={onClose}
            className="w-full py-3 px-4 border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200 font-medium rounded-lg transition-colors"
          >
            Maybe later
          </button>
        </div>
      </div>
    </div>
  </div>
);

const PaymentHandler = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    const payment = searchParams.get('payment');
    
    if (payment === 'success') {
      triggerConfetti();
      setShowSuccessModal(true);
    } else if (payment === 'cancelled') {
      setShowCancelModal(true);
    }
  }, [searchParams]);

  const handleRetry = () => {
    setShowCancelModal(false);
    navigate('/points');  // Changed from /pricing to /points
  };

  return (
    <>
      {showSuccessModal && (
        <PaymentSuccessModal 
          onClose={() => {
            setShowSuccessModal(false);
            navigate('/dashboard');
          }} 
        />
      )}
      
      {showCancelModal && (
        <PaymentCancelModal
          onClose={() => {
            setShowCancelModal(false);
            navigate('/points');  // Changed from /pricing to /points
          }}
          onRetry={handleRetry}
        />
      )}
    </>
  );
};

export default PaymentHandler;