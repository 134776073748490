import React, { useState } from 'react';
import { Play } from 'lucide-react';

const DemoVideoSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    window.open('https://youtu.be/S8-xQpSnyc0', '_blank');
  };

  return (
    <section className="py-16 relative bg-transparent">
      <div className="max-w-5xl mx-auto px-4">
        <div 
          className="relative aspect-video rounded-2xl overflow-hidden bg-gray-900 shadow-2xl cursor-pointer group"
          onClick={handlePlay}
        >
          {/* Custom Thumbnail */}
          <img
            src="/thumbnail.png"
            alt="Video Thumbnail"
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          
          {/* Play Button Overlay */}
          <div className="absolute inset-0 flex items-center justify-center bg-black/30 group-hover:bg-black/40 transition-all">
            <div className="w-20 h-20 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center group-hover:scale-110 transition-transform">
              <Play className="h-10 w-10 text-white ml-1" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoVideoSection;