import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Pencil,
  Trash2,
  Eye,
  AlertCircle,
  Clock,
  FileText,
  Archive,
  CheckCircle,
  Loader2
} from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import apiService from '../services/apiService';

const ManagePosts = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedPosts, setSelectedPosts] = useState(new Set());
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      // Get token from localStorage
      const token = localStorage.getItem('token');
      
      const response = await fetch(`${apiService.getUrl('/api/blog/posts/all')}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
  
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      setError('Failed to load posts');
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (postId) => {
    if (!window.confirm('Are you sure you want to delete this post?')) return;

    try {
      setDeleteLoading(true);
      await apiService.fetch(`/api/blog/posts/${postId}`, {
        method: 'DELETE'
      });
      setPosts(posts.filter(post => post._id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
      setError('Failed to delete post');
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleBulkDelete = async () => {
    if (!window.confirm(`Are you sure you want to delete ${selectedPosts.size} posts?`)) return;

    try {
      setDeleteLoading(true);
      await apiService.fetch('/api/blog/posts/bulk-delete', {
        method: 'POST',
        body: JSON.stringify({
          postIds: Array.from(selectedPosts)
        })
      });
      setPosts(posts.filter(post => !selectedPosts.has(post._id)));
      setSelectedPosts(new Set());
    } catch (error) {
      console.error('Error bulk deleting posts:', error);
      setError('Failed to delete posts');
    } finally {
      setDeleteLoading(false);
    }
  };

  const togglePostSelection = (postId) => {
    const newSelection = new Set(selectedPosts);
    if (newSelection.has(postId)) {
      newSelection.delete(postId);
    } else {
      newSelection.add(postId);
    }
    setSelectedPosts(newSelection);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="w-8 h-8 animate-spin text-purple-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="flex items-center p-4 text-red-600 bg-red-50 rounded-lg">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-900">
          Manage Posts ({posts.length})
        </h2>
        {selectedPosts.size > 0 && (
          <button
            onClick={handleBulkDelete}
            disabled={deleteLoading}
            className="flex items-center px-4 py-2 text-red-600 bg-red-50 rounded-lg hover:bg-red-100"
          >
            {deleteLoading ? (
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <Trash2 className="w-4 h-4 mr-2" />
            )}
            Delete Selected ({selectedPosts.size})
          </button>
        )}
      </div>

      <div className="bg-white shadow rounded-lg divide-y">
        {posts.map(post => (
          <div key={post._id} className="p-6 hover:bg-gray-50">
            <div className="flex items-start justify-between">
              <div className="flex items-start space-x-4">
                <input
                  type="checkbox"
                  checked={selectedPosts.has(post._id)}
                  onChange={() => togglePostSelection(post._id)}
                  className="mt-1 w-4 h-4 text-purple-600 rounded border-gray-300 focus:ring-purple-500"
                />
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    {post.title}
                  </h3>
                  <div className="mt-1 flex items-center space-x-4 text-sm text-gray-500">
                    <span className="flex items-center">
                      <Clock className="w-4 h-4 mr-1" />
                      {formatDistanceToNow(new Date(post.createdAt), { addSuffix: true })}
                    </span>
                    <span className={`flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      post.status === 'published' 
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {post.status === 'published' ? (
                        <CheckCircle className="w-3 h-3 mr-1" />
                      ) : (
                        <Archive className="w-3 h-3 mr-1" />
                      )}
                      {post.status}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => navigate(`/blog/${post.slug}`)}
                  className="p-2 text-gray-400 hover:text-gray-500"
                  title="View"
                >
                  <Eye className="w-5 h-5" />
                </button>
                <button
                  onClick={() => navigate(`/editor/${post._id}`)}
                  className="p-2 text-gray-400 hover:text-gray-500"
                  title="Edit"
                >
                  <Pencil className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(post._id)}
                  disabled={deleteLoading}
                  className="p-2 text-red-400 hover:text-red-500"
                  title="Delete"
                >
                  {deleteLoading ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                  ) : (
                    <Trash2 className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>
          </div>
        ))}
        
        {posts.length === 0 && (
          <div className="p-12 text-center">
            <FileText className="w-12 h-12 mx-auto text-gray-400" />
            <h3 className="mt-2 text-lg font-medium text-gray-900">No posts yet</h3>
            <p className="mt-1 text-gray-500">Get started by creating your first post</p>
            <button
              onClick={() => navigate('/editor/new')}
              className="mt-6 inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Create Post
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManagePosts;