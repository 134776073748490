import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import LiveDemo from '../components/LiveDemo';
import SEOHead from '../components/SEOHead';
import DemoVideoSection from '../components/DemoVideoSection';
import {
  Wand2, Sparkles, Brain, Zap,
  FileText, Table, MessageCircle,
  Shield, Target, Rocket, Star,
  Check, ChevronDown, ArrowRight,
  Globe, Users, RefreshCw, Layout, PlayCircle, Clock,
  Settings, Lock, Cpu
} from 'lucide-react';

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
      <SEOHead
        title="AI Content Suite - Human-Like Writing & AI Detection Bypass Tools"
        description="Transform AI content into undetectable human-like writing. Featuring AI rewriter, human article generator, smart spreadsheet assistant, and complete content generation suite."
        keywords="AI detection bypass, human writing AI, content generation, Excel AI assistant"
      />
    
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Dynamic Animation */}
        <section className="relative h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="inline-block mb-6"
            >
              <span className="bg-blue-500/20 text-blue-400 px-4 py-2 rounded-full text-sm font-medium border border-blue-500/50">
                Next-Gen AI Content Suite
              </span>
            </motion.div>

            <motion.h1 
              className="text-5xl sm:text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              style={{ 
                contain: 'content',
                contentVisibility: 'auto'
              }}
            >
              Undetectable AI Content
              <span className="block text-3xl sm:text-4xl md:text-5xl mt-4 text-white">
                Powered by Quantum AI
              </span>
            </motion.h1>
            
            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-3xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Transform AI-generated content into perfectly human writing, automate spreadsheet analysis, 
              and create engaging content - all with our advanced AI suite.
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center mb-12"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link 
                to="/register" 
                className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full font-medium hover:from-blue-700 hover:to-purple-700 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <Link
                to="#features"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById('features')?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }}
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all flex items-center justify-center"
              >
                Explore Features
                <ChevronDown className="ml-2 h-5 w-5 animate-bounce" />
              </Link>
            </motion.div>



            {/* Trust Badges */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
              className="grid grid-cols-2 md:grid-cols-4 gap-6 max-w-4xl mx-auto"
            >
              {[
                { icon: Shield, label: "100% Undetectable", sublabel: "Bypass All AI Detectors" },
                { icon: Zap, label: "Real-time Processing", sublabel: "Instant Results" },
                { icon: Users, label: "50,000+ Users", sublabel: "Trust Our Platform" },
                { icon: Star, label: "4.9/5 Rating", sublabel: "User Satisfaction" }
              ].map((badge, index) => (
                <div key={index} className="flex flex-col items-center">
                  <badge.icon className="h-8 w-8 text-blue-400 mb-2" />
                  <span className="font-semibold">{badge.label}</span>
                  <span className="text-sm text-gray-400">{badge.sublabel}</span>
                </div>
              ))}
            </motion.div>
          </motion.div>

          
{/* Animated background elements */}
<div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-blue-500/30 rounded-full filter blur-3xl animate-pulse" />
          <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-500/30 rounded-full filter blur-3xl animate-pulse delay-1000" />
        </div>
      </section>

{/* Demo Video Section */}
<DemoVideoSection />


      {/* Core Features Showcase */}
      <section id="features" className="py-24 bg-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Complete AI Content Suite
            </h2>
            <p className="text-gray-400 text-xl max-w-3xl mx-auto">
              From undetectable AI content to intelligent spreadsheet analysis, our tools 
              revolutionize your content creation workflow
            </p>
          </motion.div>


          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: Shield,
                title: "AI Rewriter",
                description: "Transform AI-generated content into undetectable human-like text that bypasses all AI detection tools while preserving your message.",
                metrics: ["100% Bypass Rate", "Instant Processing", "Meaning Preserved"],
                link: "/rewriter",
                gradient: "from-blue-500 to-purple-500"
              },
              {
                icon: Brain,
                title: "Human Writer",
                description: "Generate completely human-like articles from scratch with advanced AI that understands context, tone, and natural language patterns.",
                metrics: ["Natural Flow", "SEO Optimized", "Custom Tone"],
                link: "/human-writer",
                gradient: "from-purple-500 to-pink-500"
              },
              {
                icon: Table,
                title: "Excel AI Assistant",
                description: "Transform your spreadsheets with AI-powered analysis, automation, and intelligent data processing capabilities.",
                metrics: ["Smart Analysis", "Auto Formulas", "Data Insights"],
                link: "/spreadsheet",
                gradient: "from-pink-500 to-red-500"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8 hover:transform hover:scale-105 transition-all group"
              >
                <div className={`bg-gradient-to-r ${feature.gradient} p-4 rounded-xl w-16 h-16 flex items-center justify-center mb-6`}>
                  <feature.icon className="h-8 w-8 text-white" />
                </div>
                
                <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-400 mb-6">{feature.description}</p>
                
                <div className="space-y-3 mb-6">
                  {feature.metrics.map((metric, idx) => (
                    <div key={idx} className="flex items-center space-x-2">
                      <Check className="h-5 w-5 text-green-500" />
                      <span className="text-gray-300">{metric}</span>
                    </div>
                  ))}
                </div>

                <Link
                  to={feature.link}
                  className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors"
                >
                  Try Now
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Link>
              </motion.div>
            ))}
          </div>

          {/* Additional Tools Grid */}
          <div className="mt-16 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {[
              { icon: FileText, title: "Blog Writer", link: "/tools/blog-writer" },
              { icon: MessageCircle, title: "Social Media", link: "/tools/social-media-writer" },
              { icon: Layout, title: "Landing Pages", link: "/tools/landing-page-writer" },
              { icon: FileText, title: "Product Descriptions", link: "/tools/product-description-writer" },
              { icon: Target, title: "Ad Copy", link: "/tools/ad-copy-writer" },
              { icon: Globe, title: "SEO Content", link: "/tools/seo-optimizer" },
              { icon: MessageCircle, title: "Email Writer", link: "/tools/email-newsletter" },
              { icon: FileText, title: "Service Pages", link: "/tools/service-page-writer" }
            ].map((tool, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <Link
                  to={tool.link}
                  className="flex items-center p-4 bg-gray-800/50 rounded-xl hover:bg-gray-800 transition-all group"
                >
                  <tool.icon className="h-6 w-6 text-blue-400 mr-3" />
                  <span>{tool.title}</span>
                  <ArrowRight className="h-4 w-4 ml-auto opacity-0 group-hover:opacity-100 transition-opacity" />
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Quantum AI Technology
            </h2>
            <p className="text-gray-400 text-xl">
              Advanced AI processing that ensures undetectable, human-like content
            </p>
          </motion.div>

          <div className="relative">
            {/* Connection lines */}
            <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gradient-to-r from-blue-500 to-purple-500 transform -translate-y-1/2 hidden md:block" />

            <div className="grid md:grid-cols-3 gap-12 relative">
              {[
                {
                  step: "01",
                  title: "Content Analysis",
                  description: "Our AI analyzes patterns and structures in your content",
                  icon: Brain,
                  stat: "100ms Analysis"
                },
                {
                  step: "02",
                  title: "Pattern Transformation",
                  description: "Advanced algorithms reshape content patterns",
                  icon: RefreshCw,
                  stat: "99.9% Success Rate"
                },
                {
                  step: "03",
                  title: "Quality Assurance",
                  description: "Multiple checks ensure perfect human-like output",
                  icon: Shield,
                  stat: "100% Undetectable"
                }
              ].map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.3 }}
                  className="relative z-10"
                >
                  <div className="bg-gray-800 rounded-2xl p-8 hover:bg-gray-750 transition-all group">
                    <div className="bg-blue-500/20 w-16 h-16 rounded-full flex items-center justify-center mb-6">
                      <item.icon className="h-8 w-8" />
                    </div>
                    <div className="absolute -top-4 -left-4 w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-xl font-bold">
                      {item.step}
                    </div>
                    <h3 className="text-2xl font-bold mb-4">{item.title}</h3>
                    <p className="text-gray-400 mb-4">{item.description}</p>
                    <div className="bg-blue-500/10 px-4 py-2 rounded-full text-blue-400 text-sm inline-block">
                      {item.stat}
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Success Metrics Section */}
      <section className="py-24 bg-black relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Proven Results
            </h2>
            <p className="text-gray-400 text-xl">
              Our AI suite delivers measurable success across all content needs
            </p>
          </motion.div>

          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                metric: "99.9%",
                label: "AI Detection Bypass",
                description: "Success rate across all major detection tools",
                icon: Shield
              },
              {
                metric: "500K+",
                label: "Documents Processed",
                description: "Content pieces transformed and generated",
                icon: FileText
              },
              {
                metric: "0.001s",
                label: "Processing Speed",
                description: "Average content transformation time",
                icon: Zap
              },
              {
                metric: "50K+",
                label: "Active Users",
                description: "Trust our platform for their content needs",
                icon: Users
              }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl text-center"
              >
                <div className="bg-blue-500/20 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
                  <stat.icon className="h-8 w-8 text-blue-400" />
                </div>
                <h3 className="text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                  {stat.metric}
                </h3>
                <h4 className="text-xl font-semibold mb-2">{stat.label}</h4>
                <p className="text-gray-400">{stat.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Excel AI Assistant Showcase */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                Excel AI Assistant
              </h2>
              <p className="text-xl text-gray-300 mb-8">
                Transform your spreadsheet workflow with intelligent automation and AI-powered analysis
              </p>
              
              <div className="space-y-6 mb-8">
                {[
                  {
                    title: "Natural Language Formulas",
                    description: "Write formulas in plain English - our AI converts them to Excel functions",
                    icon: MessageCircle
                  },
                  {
                    title: "Smart Data Analysis",
                    description: "Get instant insights and patterns from your data with AI analysis",
                    icon: Brain
                  },
                  {
                    title: "Automated Workflows",
                    description: "Automate repetitive tasks with AI-powered macros and scripts",
                    icon: Settings
                  },
                  {
                    title: "Real-time Collaboration",
                    description: "Work together with AI assistance in real-time",
                    icon: Users
                  }
                ].map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.2 }}
                    className="flex items-start space-x-4"
                  >
                    <div className="bg-blue-500/20 p-3 rounded-lg">
                      <feature.icon className="h-6 w-6 text-blue-400" />
                    </div>
                    <div>
                      <h3 className="font-bold mb-1">{feature.title}</h3>
                      <p className="text-gray-400">{feature.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>

              <Link 
                to="/spreadsheet"
                className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg font-medium hover:from-blue-700 hover:to-purple-700 transition-all group"
              >
                Try Excel AI Assistant
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-2xl"
            >
              {/* Excel Preview/Demo Component */}
              <div className="aspect-video relative rounded-lg overflow-hidden bg-gray-900">
                <div className="absolute inset-0 flex items-center justify-center">
                  <LiveDemo type="spreadsheet" />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* User Testimonials */}
      <section className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Trusted by Content Creators
            </h2>
            <p className="text-gray-400 text-xl">
              See what our users have to say about their experience
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Sarah Chen",
                role: "Content Director",
                company: "TechGrowth Inc",
                quote: "The AI detection bypass feature has revolutionized our content strategy. We're now producing 3x more content with complete confidence.",
                metric: "3x Content Output"
              },
              {
                name: "Alex Rodriguez",
                role: "Digital Marketing Lead",
                company: "Growth Solutions",
                quote: "Excel AI Assistant has automated our data analysis workflow. What used to take hours now happens in seconds with incredible accuracy.",
                metric: "95% Time Saved"
              },
              {
                name: "Michael Park",
                role: "Content Creator",
                company: "CreativeFlow",
                quote: "The human writer tool produces incredibly natural content. It's impossible to distinguish from human writing - exactly what we needed.",
                metric: "100% Success Rate"
              }
            ].map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl relative group hover:transform hover:scale-105 transition-all"
              >
                <div className="absolute top-0 right-0 transform translate-x-2 -translate-y-2">
                  <Star className="h-8 w-8 text-yellow-500" />
                </div>
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center mr-4 font-bold">
                    {testimonial.name.charAt(0)}
                  </div>
                  <div>
                    <h4 className="font-bold">{testimonial.name}</h4>
                    <p className="text-sm text-gray-400">
                      {testimonial.role} at {testimonial.company}
                    </p>
                  </div>
                </div>
                <p className="text-gray-300 italic mb-4">"{testimonial.quote}"</p>
                <div className="bg-blue-500/10 px-4 py-2 rounded-full text-blue-400 text-sm inline-block">
                  {testimonial.metric}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Comparison Matrix */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Why Choose Us
            </h2>
            <p className="text-gray-400 text-xl">
              See how our AI suite compares to traditional solutions
            </p>
          </motion.div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="p-4 text-left"></th>
                  <th className="p-4 text-center">
                    <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-4 rounded-xl">
                      <h3 className="text-xl font-bold mb-2">Our AI Suite</h3>
                      <p className="text-sm text-gray-200">Next-Gen AI Platform</p>
                    </div>
                  </th>
                  <th className="p-4 text-center">
                    <div className="bg-gray-800 p-4 rounded-xl">
                      <h3 className="text-xl font-bold mb-2">Traditional Tools</h3>
                      <p className="text-sm text-gray-400">Manual Process</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {[
                  {
                    feature: "AI Detection Bypass",
                    us: "100% Success Rate",
                    them: "High Detection Risk"
                  },
                  {
                    feature: "Content Generation Speed",
                    us: "Seconds",
                    them: "Hours to Days"
                  },
                  {
                    feature: "Excel Analysis",
                    us: "AI-Powered Instant",
                    them: "Manual Process"
                  },
                  {
                    feature: "Content Quality",
                    us: "Human-Like",
                    them: "Varies"
                  },
                  {
                    feature: "Processing Time",
                    us: "Real-time",
                    them: "Manual Review"
                  }
                ].map((row, index) => (
                  <motion.tr
                    key={index}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="hover:bg-gray-800/50"
                  >
                    <td className="p-4 font-medium">{row.feature}</td>
                    <td className="p-4 text-center text-blue-400">{row.us}</td>
                    <td className="p-4 text-center text-gray-400">{row.them}</td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {/* Platform Integration */}
      <section className="py-24 bg-black relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Seamless Integration
            </h2>
            <p className="text-gray-400 text-xl">
              Works with your favorite platforms and tools
            </p>
          </motion.div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { name: "WordPress", type: "CMS" },
              { name: "Google Docs", type: "Document" },
              { name: "Microsoft Excel", type: "Spreadsheet" },
              { name: "Notion", type: "Workspace" },
              { name: "Shopify", type: "E-commerce" },
              { name: "Medium", type: "Publishing" },
              { name: "Ghost", type: "Blogging" },
              { name: "Webflow", type: "Website Builder" }
            ].map((platform, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800 rounded-xl p-6 hover:bg-gray-750 transition-all group cursor-pointer"
              >
                <div className="flex flex-col items-center space-y-4">
                  <div className="w-16 h-16 rounded-full bg-blue-500/20 flex items-center justify-center group-hover:bg-blue-500/30 transition-all">
                    <Globe className="h-8 w-8" />
                  </div>
                  <span className="font-medium">{platform.name}</span>
                  <span className="text-sm text-gray-400">{platform.type}</span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      

      {/* Final CTA */}
      <section className="py-32 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <h2 className="text-5xl md:text-7xl font-bold mb-8">
              Ready to Transform Your
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                Content Creation?
              </span>
            </h2>
            <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
              Join thousands of content creators who are already using our AI suite to create undetectable, human-like content
            </p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="flex flex-col sm:flex-row gap-4 justify-center"
            >
              <Link
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>

              <Link
                to="/demo"
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all flex items-center justify-center"
              >
                Watch Demo
                <PlayCircle className="ml-2 h-5 w-5" />
              </Link>
            </motion.div>

            <div className="mt-12 flex justify-center items-center space-x-8">
              <div className="flex items-center space-x-2">
                <Shield className="h-5 w-5 text-gray-400" />
                <span className="text-gray-400">100% Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <Clock className="h-5 w-5 text-gray-400" />
                <span className="text-gray-400">24/7 Support</span>
              </div>
              <div className="flex items-center space-x-2">
                <Star className="h-5 w-5 text-gray-400" />
                <span className="text-gray-400">5-Star Rated</span>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
    </>
  );
};

export default Home;
