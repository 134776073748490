import SEOHead from '../components/SEOHead';

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TemplateService from '../services/TemplateService';
import apiService from '../services/apiService';
import { 
  Search,
  Filter,
  Star,
  Clock,
  ChevronDown,
  ArrowRight,
  FileText,
  MessageCircle,
  Layout,
  Mail,
  Briefcase,
  Code,
  Presentation,
  ShoppingBag,
  BookOpen,
  SearchIcon,
  Building,
  HelpCircle
} from 'lucide-react';
import { TEMPLATE_CATEGORIES, getTemplatePath, getTemplateIcon } from '../config/templateConfig';

const TemplatesGallery = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [templates, setTemplates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load templates
  // Update the template loading useEffect in TemplatesGallery.js
// Update in templategallery.js, inside the useEffect
useEffect(() => {
  const loadTemplates = async () => {
    setIsLoading(true);
    try {
      const templates = await TemplateService.fetchTemplates(true); // Force refresh
      if (templates && Object.keys(templates).length > 0) {
        setTemplates(templates);
        setError('');
      } else {
        setError('No templates available');
      }
    } catch (error) {
      console.error('Error loading templates:', error);
      setError(error.message || 'Failed to load templates');
    } finally {
      setIsLoading(false);
    }
  };

  loadTemplates();
}, []);

  // Group and filter templates
  const groupedTemplates = useMemo(() => {
    // Return empty object if templates is null/undefined
    if (!templates || typeof templates !== 'object') return {};
    
    const filtered = Object.entries(templates).filter(([id, template]) => {
      // Add null checks and default values
      if (!template) return false;
      
      const matchesSearch = template.name?.toLowerCase().includes(searchTerm?.toLowerCase() || '');
      const matchesCategory = selectedCategory === 'all' || template.category === selectedCategory;
      return template.active && matchesSearch && matchesCategory;
    });
  
    return filtered.reduce((acc, [id, template]) => {
      const category = template?.category || 'uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push({ id, ...template });
      return acc;
    }, {});
  }, [templates, searchTerm, selectedCategory]);

  // Handle template selection
  const handleUseTemplate = (template) => {
    const path = getTemplatePath(template);
    if (path) {
      navigate(path);
    }
  };

  return (
    <>
      <SEOHead
  noindex={true}
/>
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">Content Templates</h1>
          <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            Choose from our library of professionally crafted templates to create engaging content in seconds
          </p>
        </div>

        {/* Search and Filter Bar */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm mb-8">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1 relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400 dark:text-gray-500" />
              <input
                type="text"
                placeholder="Search templates..."
                className="pl-10 pr-4 py-2 w-full border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          {/* Categories */}
          <div className="mt-6 flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedCategory('all')}
              className={`flex items-center px-4 py-2 rounded-lg font-medium transition-all ${
                selectedCategory === 'all'
                  ? 'bg-purple-100 dark:bg-purple-900 text-purple-700 dark:text-purple-100'
                  : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
            >
              <Layout className="h-4 w-4 mr-2" />
              All Templates
            </button>
            {Object.entries(TEMPLATE_CATEGORIES).map(([key, category]) => {
              const Icon = category.icon;
              return (
                <button
                  key={key}
                  onClick={() => setSelectedCategory(key)}
                  className={`flex items-center px-4 py-2 rounded-lg font-medium transition-all ${
                    selectedCategory === key
                      ? 'bg-purple-100 dark:bg-purple-900 text-purple-700 dark:text-purple-100'
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
                >
                  <Icon className="h-4 w-4 mr-2" />
                  {category.label}
                </button>
              );
            })}
          </div>
        </div>

        {/* Templates Grid */}
        {isLoading ? (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
          </div>
        ) : Object.keys(groupedTemplates).length > 0 ? (
          Object.entries(groupedTemplates).map(([category, categoryTemplates]) => (
            <div key={category} className="mb-12">
              <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-6">
                {TEMPLATE_CATEGORIES[category]?.label || category}
              </h2>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {categoryTemplates.map((template) => {
                  const Icon = getTemplateIcon(template.category);
                  return (
                    <div key={template.id} className="bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-shadow">
                      <div className="p-6">
                        <div className="flex items-start justify-between mb-4">
                          <div>
                            <div className="flex items-center space-x-2">
                              <Icon className="h-4 w-4 text-gray-400 dark:text-gray-500" />
                              <span className="text-sm text-gray-500 dark:text-gray-400 capitalize">
                                {TEMPLATE_CATEGORIES[template.category]?.label || template.category}
                              </span>
                            </div>
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mt-2">
                              {template.name}
                            </h3>
                          </div>
                          {template.premium && (
                            <span className="px-2 py-1 bg-purple-100 dark:bg-purple-900 text-purple-700 dark:text-purple-100 rounded-full text-xs font-medium">
                              Premium
                            </span>
                          )}
                        </div>
                        
                        <p className="text-gray-600 dark:text-gray-400 text-sm mb-4">
                          {template.description}
                        </p>

                        <div className="flex items-center justify-between text-sm text-gray-500 dark:text-gray-400 mb-4">
                          <div className="flex items-center">
                            <Clock className="h-4 w-4 mr-1" />
                            ~2 min
                          </div>
                          <div>
                            {template.pointsCost} point{template.pointsCost !== 1 ? 's' : ''}/word
                          </div>
                        </div>
                      </div>
                      
                      <div className="border-t border-gray-100 dark:border-gray-700 p-4">
                        <button 
                          onClick={() => handleUseTemplate(template)}
                          className="w-full flex items-center justify-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                        >
                          Use Template
                          <ArrowRight className="h-4 w-4 ml-2" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-12">
            <p className="text-gray-500 dark:text-gray-400">No templates found matching your criteria</p>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default TemplatesGallery;

// 2. Second file to update - src/components/GenericTemplateWriter.js
// Find the file and replace all references to colors with their dark mode variants
// Here are the key changes to make in the classes:

// Change:
// bg-white -> bg-white dark:bg-gray-800
// text-gray-900 -> text-gray-900 dark:text-white
// text-gray-600 -> text-gray-600 dark:text-gray-400
// border-gray-300 -> border-gray-300 dark:border-gray-600
// bg-gray-50 -> bg-gray-50 dark:bg-gray-900
// bg-blue-50 -> bg-blue-50 dark:bg-blue-900