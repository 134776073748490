import React, { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { 
  Wand2, 
  Copy, 
  CheckCircle2,
  AlertCircle,
  Sparkles,
  Shield,
  Zap,
  Brain,
  Target,
  Lock,
  ChevronDown,
  RefreshCcw,
  Square,
  Star
} from 'lucide-react';
import GrammarCheck from './GrammarFixDialog';

const AIRewriter = () => {
  const [content, setContent] = useState('');
  const [userPoints, setUserPoints] = useState(0);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [rewrittenContent, setRewrittenContent] = useState({
    text: '',
    formattedText: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [error, setError] = useState('');
  const [openFaq, setOpenFaq] = useState(null);
  const [templateConfig, setTemplateConfig] = useState(null);

  // Template Loading
  // Replace the template loading useEffect in AIRewriter.js
  const formatContent = (text) => {
    if (!text) return '';
    
    // Format bold text (enclosed in **)
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Format headings (lines starting with # or ##)
    formattedText = formattedText.replace(/^# (.*?)$/gm, '<h1 class="text-2xl font-bold my-4">$1</h1>');
    formattedText = formattedText.replace(/^## (.*?)$/gm, '<h2 class="text-xl font-bold my-3">$1</h2>');
    formattedText = formattedText.replace(/^### (.*?)$/gm, '<h3 class="text-lg font-bold my-2">$1</h3>');
    
    // Convert newlines to <br /> tags
    formattedText = formattedText.replace(/\n/g, '<br />');
    
    return formattedText;
  };

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication required');
        }
  
        const response = await fetch(apiService.getUrl('/api/templates'), {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-store'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch templates');
        }
  
        const templates = await response.json();
        
        if (templates['ai-rewriter']) {
          try {
            const config = JSON.parse(templates['ai-rewriter'].pythonCode);
            setTemplateConfig({
              ...templates['ai-rewriter'],
              promptConfig: config
            });
          } catch (error) {
            console.error('Error parsing template config:', error);
            setTemplateConfig(templates['ai-rewriter']);
          }
          localStorage.setItem('promptTemplates', JSON.stringify(templates));
        } else {
          const savedTemplates = localStorage.getItem('promptTemplates');
          if (savedTemplates) {
            const parsed = JSON.parse(savedTemplates);
            if (parsed['ai-rewriter']) {
              try {
                const config = JSON.parse(parsed['ai-rewriter'].pythonCode);
                setTemplateConfig({
                  ...parsed['ai-rewriter'],
                  promptConfig: config
                });
              } catch (error) {
                console.error('Error parsing saved template config:', error);
                setTemplateConfig(parsed['ai-rewriter']);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error loading template:', error);
        const savedTemplates = localStorage.getItem('promptTemplates');
        if (savedTemplates) {
          const parsed = JSON.parse(savedTemplates);
          if (parsed['ai-rewriter']) {
            try {
              const config = JSON.parse(parsed['ai-rewriter'].pythonCode);
              setTemplateConfig({
                ...parsed['ai-rewriter'],
                promptConfig: config
              });
            } catch (error) {
              console.error('Error parsing saved template config:', error);
              setTemplateConfig(parsed['ai-rewriter']);
            }
          }
        }
      }
    };
  
    loadTemplates();
  }, []);

  // Points Loading
  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(apiService.getUrl('/api/points'), {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setUserPoints(data.balance);
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    };
  
    fetchPoints();
  }, []);

  // Input Handler
  const handleInputChange = (e) => {
    const newContent = e.target.value;
    setContent(newContent);
    if (error) setError('');

    const wordCount = newContent.trim().split(/\s+/).length;
    const pointsPerWord = templateConfig?.pointsCost || 2;
    const cost = newContent.trim() ? wordCount * pointsPerWord : 0;
    setEstimatedCost(cost);
  };

  // Copy Handler
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(rewrittenContent.text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      setError('Failed to copy to clipboard');
    }
  };

  // Rewrite Handler
  // Update the handleRewrite function to use the new format:
const handleRewrite = async () => {
  if (!content.trim()) {
    setError('Please enter some content to rewrite');
    return;
  }

  setIsProcessing(true);
  setError('');
  setRewrittenContent({ text: '', formattedText: '' });

  try {
    const token = localStorage.getItem('token');
    const inputWordCount = content.trim().split(/\s+/).length;
    const pointsPerWord = templateConfig?.pointsCost || 2;
    const pointsToDeduct = inputWordCount * pointsPerWord;

    if (userPoints < pointsToDeduct) {
      throw new Error(`Insufficient points. You need ${pointsToDeduct} points. Current balance: ${userPoints}`);
    }

    let requestBody;
    try {
      if (templateConfig?.promptConfig) {
        // Use the new format
        const config = templateConfig.promptConfig;
        
        // Create a deep copy of the config
        const promptData = JSON.parse(JSON.stringify(config));
        
        // Replace the placeholder in the messages
        promptData.messages = promptData.messages.map(msg => ({
          ...msg,
          content: msg.content.map(contentBlock => ({
            ...contentBlock,
            text: contentBlock.text.replace(/{content-to-rewrite}/g, content)
          }))
        }));

        requestBody = {
          ...promptData,
          metadata: {
            input_word_count: inputWordCount,
            points_to_deduct: pointsToDeduct,
            feature: "ai-rewriter"
          }
        };
      } else {
        throw new Error('Template configuration not found');
      }
    } catch (error) {
      console.error('Try Again', error);
      throw new Error('Try Again');
    }

    const response = await fetch(apiService.getUrl('/api/proxy/anthropic/stream'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody)
      
    });

    
if (!response.ok) {
  const errorData = await response.json();
  throw new Error(errorData.error?.message || 'Failed to generate content');
}

const reader = response.body.getReader();
const decoder = new TextDecoder();
let accumulatedText = '';

while (true) {
  const { value, done } = await reader.read();
  if (done) break;
  
  const chunk = decoder.decode(value);
  const lines = chunk.split('\n');
  
  for (const line of lines) {
    if (line.startsWith('data: ')) {
      const data = line.slice(6);
      if (data.trim() === '[DONE]') continue;
      
      try {
        const parsed = JSON.parse(data);
        if (parsed.type === 'content_block_delta' && parsed.delta?.text) {
          accumulatedText += parsed.delta.text;
          setRewrittenContent({
            text: accumulatedText,
            formattedText: accumulatedText.replace(/\n/g, '<br />')
          });
        } else if (parsed.type === 'points_update') {
          setUserPoints(parsed.remaining_balance);
          window.dispatchEvent(new CustomEvent('pointsUpdate', { 
            detail: { 
              balance: parsed.remaining_balance 
            }
          }));
        }
      } catch (e) {
        console.error('Error parsing stream data:', e);
      }
    }
  }
}

} catch (err) {
console.error('Generation error:', err);
setError(err.message || 'An error occurred. Please try again.');
} finally {
setIsProcessing(false);
}
};

  // FAQ toggle handler
  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white dark:from-gray-900 dark:to-gray-800">
      {/* Premium Feature Banner */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-2">
            <Lock className="h-4 w-4" />
            <span>Premium Feature: Uses 2x Points for Enhanced AI Detection Bypass</span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-16">
          {/* Left Column - Input */}
{/* Left Column - Input */}
<div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 flex flex-col h-[600px]">
  <div className="flex-1 flex flex-col">
    <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Original Content</h2>
    <div className="flex-1 flex flex-col">
      <textarea
        value={content}
        onChange={handleInputChange}
        placeholder="Paste your AI-generated content here..."
        className={`w-full flex-1 p-4 border border-gray-200 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent resize-none bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 ${
          content.trim() ? 'mb-4' : 'mb-0'
        }`}
      />
      
      {/* Points Info Display */}
      {content.trim() && (
        <div className="space-y-2">
          <div className="flex justify-between items-center text-sm text-gray-600 dark:text-gray-300">
            <span>Words:</span>
            <span className="font-medium">{content.trim().split(/\s+/).length}</span>
          </div>
          <div className="flex justify-between items-center text-sm">
            <span className="text-gray-600 dark:text-gray-300">Estimated Cost:</span>
            <span className="font-medium text-purple-600 dark:text-purple-400">{estimatedCost} points</span>
          </div>
          <div className="flex justify-between items-center text-sm">
            <span className="text-gray-600 dark:text-gray-300">Your Balance:</span>
            <span className="font-medium text-gray-900 dark:text-white">{userPoints} points</span>
          </div>
          <div className="flex justify-between items-center text-sm">
            <span className="text-gray-600 dark:text-gray-300">Remaining After:</span>
            <span className={`font-medium ${userPoints - estimatedCost < 0 ? 'text-red-600 dark:text-red-400' : 'text-green-600 dark:text-green-400'}`}>
              {userPoints - estimatedCost} points
            </span>
          </div>
        </div>
      )}
    </div>
  </div>

  <div className="mt-6">
    <button
      onClick={handleRewrite}
      disabled={isProcessing || !content.trim()}
      className={`w-full flex items-center justify-center px-4 py-3 rounded-lg text-white font-medium ${
        isProcessing || !content.trim()
          ? 'bg-purple-400 dark:bg-purple-500 cursor-not-allowed'
          : 'bg-purple-600 hover:bg-purple-700 dark:bg-purple-600 dark:hover:bg-purple-700'
      }`}
    >
      {isProcessing ? (
        <>
          <Sparkles className="animate-spin h-5 w-5 mr-2" />
          Humanizing...
        </>
      ) : (
        <>
          <Wand2 className="h-5 w-5 mr-2" />
          Humanize Content
        </>
      )}
    </button>
  </div>
</div>

          {/* Right Column - Output */}
{/* Right Column - Output */}
<div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 flex flex-col h-[600px]">
  <div className="flex items-center justify-between mb-6">
    <h2 className="text-xl font-bold text-gray-900 dark:text-white">Humanized Content</h2>
    {rewrittenContent.text && (
      <button
        onClick={handleCopy}
        className="text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300"
        title="Copy to clipboard"
      >
        {copySuccess ? (
          <CheckCircle2 className="h-5 w-5 text-green-500 dark:text-green-400" />
        ) : (
          <Copy className="h-5 w-5" />
        )}
      </button>
    )}
  </div>

  {error && (
    <div className="flex items-center text-red-600 dark:text-red-400 mb-4">
      <AlertCircle className="h-5 w-5 mr-2" />
      {error}
    </div>
  )}

  <div className="flex-1 flex flex-col">
    <div className="flex-1 relative">
      {rewrittenContent.text ? (
        <div className="absolute inset-0 overflow-y-auto p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
          <div 
            className="prose dark:prose-invert max-w-none text-gray-900 dark:text-white"
            dangerouslySetInnerHTML={{ 
              __html: rewrittenContent.formattedText 
            }}
          />
        </div>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center text-center text-gray-500 dark:text-gray-400 border border-gray-200 dark:border-gray-700 rounded-lg">
          <div>
            <Wand2 className="h-12 w-12 mx-auto mb-4 opacity-50" />
            <p>Your humanized content will appear here</p>
            <p className="text-sm">Fill in the details and click "Humanize Content" to create content</p>
          </div>
        </div>
      )}
    </div>

    {rewrittenContent.text && (
      <div className="mt-4">
        <GrammarCheck
          rewrittenContent={rewrittenContent.text}
          onCopyOld={() => {
            // Just close the dialog - keep existing content
          }}
          onFixGrammar={async (prompt) => {
            try {
              setIsProcessing(true);
              const token = localStorage.getItem('token');
              
              const response = await fetch(apiService.getUrl('/api/proxy/grammar-fix'), {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ prompt })
              });

              if (!response.ok) {
                throw new Error('Failed to fix grammar');
              }

              const reader = response.body.getReader();
              const decoder = new TextDecoder();
              let accumulatedText = '';

              while (true) {
                const { value, done } = await reader.read();
                if (done) break;
                
                const chunk = decoder.decode(value);
                const lines = chunk.split('\n');
                
                for (const line of lines) {
                  if (line.startsWith('data: ')) {
                    const data = line.slice(6);
                    if (data.trim() === '[DONE]') continue;
                    
                    try {
                      const parsed = JSON.parse(data);
                      if (parsed.type === 'content_block_delta' && parsed.delta?.text) {
                        accumulatedText += parsed.delta.text;
                        setRewrittenContent({
                          text: accumulatedText,
                          formattedText: formatContent(accumulatedText)
                        });
                      }
                    } catch (e) {
                      console.error('Error parsing stream data:', e);
                    }
                  }
                }
              }
            } catch (err) {
              setError(err.message || 'Failed to fix grammar');
            } finally {
              setIsProcessing(false);
            }
          }}
        />
      </div>
    )}
  </div>
</div>
          
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
            <Shield className="h-12 w-12 text-purple-600 dark:text-purple-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">100% Undetectable</h3>
            <p className="text-gray-600 dark:text-gray-400">Advanced rewriting that bypasses all AI detection tools while maintaining natural human writing patterns.</p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
            <Target className="h-12 w-12 text-purple-600 dark:text-purple-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">SEO Optimized</h3>
            <p className="text-gray-600 dark:text-gray-400">Enhanced content structure and readability that helps your content rank higher in search results.</p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
            <Brain className="h-12 w-12 text-purple-600 dark:text-purple-400 mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">Context Aware</h3>
            <p className="text-gray-600 dark:text-gray-400">Maintains your original meaning while improving tone, style, and readability.</p>
          </div>
        </div>

        {/* Premium Feature Pricing */}
        <div className="bg-gradient-to-r from-purple-600 to-indigo-600 rounded-xl shadow-lg p-8 text-white mb-16">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Premium Feature Pricing</h2>
            <p className="text-xl mb-8">Our AI Humanizer uses 2x points per word for enhanced processing</p>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="bg-white/10 rounded-lg p-6">
                <h3 className="font-bold mb-2">Example Cost</h3>
                <p>500-word article = 1,000 points</p>
              </div>
              <div className="bg-white/10 rounded-lg p-6">
                <h3 className="font-bold mb-2">Quality Guarantee</h3>
                <p>100% Pass AI Detection Tests</p>
              </div>
              <div className="bg-white/10 rounded-lg p-6">
                <h3 className="font-bold mb-2">Time Saved</h3>
                <p>Rewrite in Seconds vs Hours</p>
              </div>
            </div>
          </div>
        </div>

        {/* FAQs */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-8">
          <h2 className="text-2xl font-bold mb-8 text-center text-gray-900 dark:text-white">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200 dark:border-gray-700 last:border-0">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-gray-900 dark:text-white">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 dark:text-gray-400 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600 dark:text-gray-400">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// FAQ Data with updated premium feature information
const faqs = [
  {
    question: "Why does the AI Humanizer use 2x points?",
    answer: "The AI Humanizer is a premium feature that uses advanced processing algorithms to ensure your content is completely undetectable by AI detection tools. This enhanced processing requires more computational power, hence the 2x points cost. The result is higher quality, more natural content that ranks better in search engines."
  },
  {
    question: "How effective is the AI detection bypass?",
    answer: "Our AI Humanizer has been tested against all major AI detection tools and consistently achieves undetectable status. It can: bypass Copyleaks AI detection, bypass ZeroGPT AI detection, bypass Quilbot AI detection,bypass TurnitIn AI detection, and bypass Originality.ai AI detection."
  },
  {
    question: "Will the rewritten content maintain my original message?",
    answer: "Yes! While the words and structure may change to sound more natural, we ensure that the core message, key points, and important details of your content remain intact throughout the rewriting process."
  },
  {
    question: "How many points will my content cost?",
    answer: "The point cost is calculated at 2 points per word. For example, a 500-word article will cost 1,000 points. This premium pricing reflects the advanced processing needed for high-quality, undetectable results."
  },
  {
    question: "How fast is the rewriting process?",
    answer: "Most content is rewritten within seconds. Longer pieces (1000+ words) may take a minute or two to ensure quality results. You can see the real-time progress as your content is being rewritten."
  },
  {
    question: "Can I edit the rewritten content?",
    answer: "Absolutely! Once your content is rewritten, you can copy it and make any desired adjustments. The rewritten content is fully editable while maintaining its AI-detection-proof characteristics."
  }
];

export default AIRewriter;