import React, { useState, useEffect } from 'react';
import AdminPointsManager from './AdminPointsManager';
import TemplateService from '../services/TemplateService';
import SEOHead from './SEOHead';
import apiService from '../services/apiService';
import PointsResetTester from './PointsResetTester';
import UserManagementPanel from './UserManagementPanel';
import { Users } from 'lucide-react';
import { 
  Settings,
  Save,
  ChevronDown,
  ChevronRight,
  Edit3,
  FileText,
  MessageCircle,
  Layout,
  Plus,
  Minus,
  AlertCircle,
  Briefcase,
  Trash2,
  Code,
  HelpCircle,
  ShoppingBag,
  BookOpen,
  Search,
  Info,
  Star,
  Check,
  Presentation  // Add this line
} from 'lucide-react';

const AdminDashboard = () => {
  const [activeView, setActiveView] = useState('templates');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [spreadsheetModels, setSpreadsheetModels] = useState({
    spreadsheetModel: '',
    tableAiModel: ''
  });

  // State for template configuration
  const [templates, setTemplates] = useState({});
  const [promptConfig, setPromptConfig] = useState({
    model: '',
    max_tokens: 4096,
    temperature: 0.7,
    messages: [{
      role: 'user',
      content: [{
        type: 'text',
        text: ''
      }]
    }],
    top_p: 1,
    top_k: 50,
    frequency_penalty: 0,
    presence_penalty: 0,
    stop: [],
  });

  // Helper for showing messages
  const showSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => setSuccess(''), 3000);
  };

  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(''), 3000);
  };

  // Categories configuration
  const categories = {
    'long-form': {
      icon: FileText,
      label: 'Long-Form Content',
      tools: [
        { id: 'blog-generator', name: 'Blog Post Generator', pointsCost: 1 },
        { id: 'article-writer', name: 'Article Writer', pointsCost: 1 },
        { id: 'ai-rewriter', name: 'Content Humanizer', pointsCost: 2, premium: true },
        { id: 'human-writer', name: 'Human Article Writer', pointsCost: 2, premium: true },
        { id: 'seo-writer', name: 'SEO Content Writer', pointsCost: 1 },
        { id: 'content-rewriter', name: 'Content Rewriter', pointsCost: 1 }
      ]
    },
    'spreadsheet': {
  icon: FileText,
  label: 'Spreadsheet Settings',
  tools: [
    { id: 'spreadsheet-models', name: 'Model Configuration', pointsCost: 0 }
  ]
},
    'marketing': {
      icon: MessageCircle,
      label: 'Marketing Content',
      tools: [
        { id: 'social-media', name: 'Social Media Post Generator', pointsCost: 1 },
        { id: 'email-newsletter', name: 'Email Newsletter Writer', pointsCost: 1 },
        { id: 'ad-copy', name: 'Ad Copy Generator', pointsCost: 1 },
        { id: 'sales-copy', name: 'Sales Copy Writer', pointsCost: 1 },
        { id: 'landing-page', name: 'Landing Page Generator', pointsCost: 1 }
      ]
    },'website': {
      icon: Layout,
      label: 'Website Content',
      tools: [
        { id: 'about-page', name: 'About Page Writer', pointsCost: 1 },
        { id: 'service-page', name: 'Service Page Creator', pointsCost: 1 },
        { id: 'features-page', name: 'Features Page Writer', pointsCost: 1 },
        { id: 'faq-generator', name: 'FAQ Generator', pointsCost: 1 },
        { id: 'privacy-policy', name: 'Privacy Policy Generator', pointsCost: 1 },
        { id: 'terms', name: 'Terms & Conditions Generator', pointsCost: 1 }
      ]
    },
    'business': {
      icon: Briefcase,
      label: 'Business Writing',
      tools: [
        { id: 'proposal', name: 'Business Proposal Writer', pointsCost: 1 },
        { id: 'bio', name: 'Professional Bio Writer', pointsCost: 1 },
        { id: 'company-profile', name: 'Company Profile Generator', pointsCost: 1 },
        { id: 'case-study', name: 'Case Study Writer', pointsCost: 1 },
        { id: 'white-paper', name: 'White Paper Generator', pointsCost: 1 },
        { id: 'press-release', name: 'Press Release Writer', pointsCost: 1 }
      ]
    },
    'technical': {
      icon: Code,
      label: 'Technical Writing',
      tools: [
        { id: 'tech-docs', name: 'Technical Documentation Writer', pointsCost: 1 },
        { id: 'api-docs', name: 'API Documentation Generator', pointsCost: 1 },
        { id: 'user-manual', name: 'User Manual Creator', pointsCost: 1 },
        { id: 'how-to', name: 'How-To Guide Generator', pointsCost: 1 },
        { id: 'tutorial', name: 'Tutorial Writer', pointsCost: 1 }
      ]
    },
    'marketing-materials': {
      icon: Presentation,
      label: 'Marketing Materials',
      tools: [
        { id: 'brochure', name: 'Brochure Content Writer', pointsCost: 1 },
        { id: 'presentation', name: 'Presentation Content Generator', pointsCost: 1 },
        { id: 'pitch-deck', name: 'Pitch Deck Content Creator', pointsCost: 1 },
        { id: 'company-newsletter', name: 'Company Newsletter Writer', pointsCost: 1 },
        { id: 'event-desc', name: 'Event Description Writer', pointsCost: 1 }
      ]
    },
    'ecommerce': {
      icon: ShoppingBag,
      label: 'E-commerce Content',
      tools: [
        { id: 'product-desc-gen', name: 'Product Description Generator', pointsCost: 1 },
        { id: 'category-content', name: 'Category Page Content', pointsCost: 1 },
        { id: 'collection-desc', name: 'Collection Description Writer', pointsCost: 1 },
        { id: 'product-review', name: 'Product Review Generator', pointsCost: 1 },
        { id: 'spec-writer', name: 'Specification Writer', pointsCost: 1 }
      ]
    },
    'academic': {
      icon: BookOpen,
      label: 'Academic Content',
      tools: [
        { id: 'research-outline', name: 'Research Paper Outline Generator', pointsCost: 1 },
        { id: 'essay-structure', name: 'Essay Structure Creator', pointsCost: 1 },
        { id: 'academic-abstract', name: 'Academic Abstract Writer', pointsCost: 1 },
        { id: 'lit-review', name: 'Literature Review Helper', pointsCost: 1 },
        { id: 'bibliography', name: 'Bibliography Formatter', pointsCost: 1 }
      ]
    },
    'seo': {
      icon: Search,
      label: 'SEO Tools',
      tools: [
        { id: 'meta-desc', name: 'Meta Description Generator', pointsCost: 1 },
        { id: 'title-tag', name: 'Title Tag Optimizer', pointsCost: 1 },
        { id: 'keyword-research', name: 'Keyword Research Helper', pointsCost: 1 },
        { id: 'seo-optimizer', name: 'SEO Content Optimizer', pointsCost: 1 },
        { id: 'schema-markup', name: 'Schema Markup Generator', pointsCost: 1 }
      ]
    },
    'specialized': {
      icon: Star,
      label: 'Specialized Content',
      tools: [
        { id: 'real-estate', name: 'Real Estate Listing Description', pointsCost: 1 },
        { id: 'job-desc', name: 'Job Description Generator', pointsCost: 1 },
        { id: 'menu-desc', name: 'Menu Description Writer', pointsCost: 1 },
        { id: 'portfolio', name: 'Portfolio Content Creator', pointsCost: 1 },
        { id: 'podcast-notes', name: 'Podcast Show Notes Generator', pointsCost: 1 }
      ]
    }
  };

  // Load templates
  useEffect(() => {
    const loadTemplates = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await fetch(apiService.getUrl('/api/admin/templates'), {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch templates');
        }
    
        const serverTemplates = await response.json();
        const templatesObj = {};
        
        // Transform array to object with identifier as key
        serverTemplates.forEach(template => {
          templatesObj[template.identifier] = template;
        });
        
        setTemplates(templatesObj);
        await TemplateService.setCachedTemplates(templatesObj);
      } catch (error) {
        console.error('Error loading templates:', error);
      } finally {
        setLoading(false);
      }
    };

    loadTemplates();
  }, []);
  // Add these handler functions after the loadTemplates useEffect:

  const addMessage = () => {
    setPromptConfig(prev => ({
      ...prev,
      messages: [
        ...prev.messages,
        {
          role: 'user',
          content: [{
            type: 'text',
            text: ''
          }]
        }
      ]
    }));
  };

  const removeMessage = (index) => {
    setPromptConfig(prev => ({
      ...prev,
      messages: prev.messages.filter((_, i) => i !== index)
    }));
  };

  const updateMessageRole = (index, role) => {
    setPromptConfig(prev => ({
      ...prev,
      messages: prev.messages.map((msg, i) => 
        i === index ? { ...msg, role } : msg
      )
    }));
  };

  const updateMessageContent = (index, text) => {
    setPromptConfig(prev => ({
      ...prev,
      messages: prev.messages.map((msg, i) => 
        i === index ? {
          ...msg,
          content: [{
            type: 'text',
            text
          }]
        } : msg
      )
    }));
  };

  const addStopSequence = () => {
    setPromptConfig(prev => ({
      ...prev,
      stop: [...prev.stop, '']
    }));
  };

  const removeStopSequence = (index) => {
    setPromptConfig(prev => ({
      ...prev,
      stop: prev.stop.filter((_, i) => i !== index)
    }));
  };

  const updateStopSequence = (index, value) => {
    setPromptConfig(prev => ({
      ...prev,
      stop: prev.stop.map((seq, i) => 
        i === index ? value : seq
      )
    }));
  };

  const handleModelConfigChange = (field, value) => {
    // Handle numeric fields
    if (['max_tokens', 'top_k'].includes(field)) {
      value = parseInt(value) || 0;
    } else if (['temperature', 'top_p', 'frequency_penalty', 'presence_penalty'].includes(field)) {
      value = parseFloat(value) || 0;
    }

    setPromptConfig(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = async () => {
    try {
      if (selectedTemplate) {
        // Format the template configuration
        const formattedConfig = {
          model: promptConfig.model,
          max_tokens: promptConfig.max_tokens,
          temperature: promptConfig.temperature,
          messages: promptConfig.messages,
          ...(promptConfig.top_p !== 1 && { top_p: promptConfig.top_p }),
          ...(promptConfig.top_k !== 50 && { top_k: promptConfig.top_k }),
          ...(promptConfig.frequency_penalty !== 0 && { frequency_penalty: promptConfig.frequency_penalty }),
          ...(promptConfig.presence_penalty !== 0 && { presence_penalty: promptConfig.presence_penalty }),
          ...(promptConfig.stop.length > 0 && { stop: promptConfig.stop })
        };

        // Update templates state with the new configuration
        const updatedTemplates = {
          [selectedTemplate]: {
            ...templates[selectedTemplate],
            pythonCode: JSON.stringify(formattedConfig, null, 2),
            name: templates[selectedTemplate]?.name,
            description: templates[selectedTemplate]?.description,
            pointsCost: templates[selectedTemplate]?.pointsCost,
            premium: templates[selectedTemplate]?.premium,
            active: templates[selectedTemplate]?.active,
            identifier: selectedTemplate
          }
        };

        const token = localStorage.getItem('token');
        const response = await fetch(apiService.getUrl('/api/templates'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(updatedTemplates)
        });

        if (!response.ok) {
          throw new Error('Failed to save template');
        }

        const savedTemplates = await response.json();
        
        // Update local state with saved template
        setTemplates(prev => ({
          ...prev,
          [selectedTemplate]: savedTemplates[selectedTemplate]
        }));
        
        await TemplateService.setCachedTemplates(templates);
        
        showSuccess('Template saved successfully!');
      }
    } catch (error) {
      console.error('Error saving template:', error);
      showError(error.message || 'Failed to save template');
    }
  };

  const loadTemplateConfig = (template) => {
    try {
      if (template?.pythonCode) {
        const config = JSON.parse(template.pythonCode);
        setPromptConfig({
          model: config.model || '',
          max_tokens: config.max_tokens || 4096,
          temperature: config.temperature || 0.7,
          messages: config.messages || [{
            role: 'user',
            content: [{ type: 'text', text: '' }]
          }],
          top_p: config.top_p || 1,
          top_k: config.top_k || 50,
          frequency_penalty: config.frequency_penalty || 0,
          presence_penalty: config.presence_penalty || 0,
          stop: config.stop || []
        });
      }
    } catch (error) {
      console.error('Error parsing template config:', error);
      showError('Invalid template configuration');
    }
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  const selectTemplate = (toolId) => {
    setSelectedTemplate(toolId);
    if (templates[toolId]) {
      loadTemplateConfig(templates[toolId]);
    }
  };
  // Continue after the previous functions:

  useEffect(() => {
    const loadSpreadsheetModels = async () => {
      try {
        const response = await fetch(apiService.getUrl('/api/admin/models/spreadsheet'), {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) throw new Error('Failed to load models');
        const data = await response.json();
        setSpreadsheetModels(data);
      } catch (error) {
        console.error('Error loading spreadsheet models:', error);
        showError('Failed to load spreadsheet models');
      }
    };
  
    if (selectedTemplate === 'spreadsheet-models') {
      loadSpreadsheetModels();
    }
  }, [selectedTemplate]);
  
  return (
    <>
      <SEOHead />
      <div className="min-h-screen bg-gray-50">
        {/* Top Navigation */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16 items-center">
              <h1 className="text-xl font-bold text-gray-900">Admin Dashboard</h1>
              <div className="flex space-x-4">
  <button
    onClick={() => setActiveView('templates')}
    className={`px-4 py-2 rounded-lg ${
      activeView === 'templates' 
        ? 'bg-purple-100 text-purple-700' 
        : 'text-gray-600 hover:bg-gray-100'
    }`}
  >
    <Settings className="h-5 w-5 inline-block mr-2" />
    Templates
  </button>
  <button
    onClick={() => setActiveView('users')}
    className={`px-4 py-2 rounded-lg ${
      activeView === 'users' 
        ? 'bg-purple-100 text-purple-700' 
        : 'text-gray-600 hover:bg-gray-100'
    }`}
  >
    <Users className="h-5 w-5 inline-block mr-2" />
    Users
  </button>
  <button
    onClick={() => setActiveView('editor')}
    className={`px-4 py-2 rounded-lg ${
      activeView === 'editor' 
        ? 'bg-purple-100 text-purple-700' 
        : 'text-gray-600 hover:bg-gray-100'
    }`}
  >
    <Edit3 className="h-5 w-5 inline-block mr-2" />
    Editor
  </button>
</div>
            </div>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {activeView === 'templates' ? (
            <div className="grid grid-cols-12 gap-8">
              {/* Categories Sidebar */}
              <div className="col-span-3 bg-white rounded-lg shadow-sm p-4">
                <div className="space-y-1">
                  {Object.entries(categories).map(([categoryId, category]) => (
                    <div key={categoryId}>
                      <button
                        onClick={() => toggleCategory(categoryId)}
                        className="w-full flex items-center justify-between p-2 rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex items-center">
                          <category.icon className="h-5 w-5 text-gray-400 mr-2" />
                          <span className="text-sm font-medium text-gray-700">
                            {category.label}
                          </span>
                        </div>
                        {expandedCategory === categoryId ? (
                          <ChevronDown className="h-4 w-4 text-gray-400" />
                        ) : (
                          <ChevronRight className="h-4 w-4 text-gray-400" />
                        )}
                      </button>
                      
                      {expandedCategory === categoryId && (
                        <div className="ml-7 mt-1 space-y-1">
                          {category.tools.map(tool => (
                            <button
                              key={tool.id}
                              onClick={() => selectTemplate(tool.id)}
                              className={`w-full text-left px-3 py-2 text-sm rounded-md ${
                                selectedTemplate === tool.id
                                  ? 'bg-purple-50 text-purple-700'
                                  : 'text-gray-600 hover:bg-gray-50'
                              }`}
                            >
                              {tool.name}
                              {tool.premium && (
                                <span className="ml-2 px-1.5 py-0.5 text-xs bg-purple-100 text-purple-700 rounded">
                                  Premium
                                </span>
                              )}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Template Configuration Panel */}
<div className="col-span-9 bg-white rounded-lg shadow-sm p-6">
  {selectedTemplate === 'spreadsheet-models' ? (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-bold text-gray-900">Spreadsheet Model Configuration</h2>
          <p className="text-sm text-gray-500 mt-1">Configure AI models for spreadsheet features</p>
        </div>
      </div>

      {/* Status Messages */}
      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}
      {success && (
        <div className="mb-4 p-3 bg-green-50 text-green-700 rounded-lg flex items-center">
          <Check className="h-5 w-5 mr-2" />
          {success}
        </div>
      )}

      {/* Spreadsheet Model */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Spreadsheet Content Generator Model
          <span className="ml-2 text-sm text-gray-500">
            (Used for single/multi-cell content generation)
          </span>
        </label>
        <div className="flex space-x-4">
          <input
            type="text"
            value={spreadsheetModels.spreadsheetModel}
            onChange={(e) => setSpreadsheetModels(prev => ({
              ...prev,
              spreadsheetModel: e.target.value
            }))}
            placeholder="e.g., google/gemini-pro"
            className="flex-1 px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch(apiService.getUrl('/api/admin/models/spreadsheet'), {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  },
                  body: JSON.stringify({
                    ...spreadsheetModels,
                    spreadsheetModel: spreadsheetModels.spreadsheetModel
                  })
                });
                
                if (!response.ok) throw new Error('Failed to save model');
                showSuccess('Spreadsheet model updated successfully');
              } catch (error) {
                console.error('Error saving spreadsheet model:', error);
                showError('Failed to save spreadsheet model');
              }
            }}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Save Spreadsheet Model
          </button>
        </div>
      </div>

      {/* Table AI Model */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Table AI Analysis Model
          <span className="ml-2 text-sm text-gray-500">
            (Used for data analysis and visualization)
          </span>
        </label>
        <div className="flex space-x-4">
          <input
            type="text"
            value={spreadsheetModels.tableAiModel}
            onChange={(e) => setSpreadsheetModels(prev => ({
              ...prev,
              tableAiModel: e.target.value
            }))}
            placeholder="e.g., deepseek/deepseek-chat"
            className="flex-1 px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch(apiService.getUrl('/api/admin/models/spreadsheet'), {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  },
                  body: JSON.stringify({
                    ...spreadsheetModels,
                    tableAiModel: spreadsheetModels.tableAiModel
                  })
                });
                
                if (!response.ok) throw new Error('Failed to save model');
                showSuccess('Table AI model updated successfully');
              } catch (error) {
                console.error('Error saving table AI model:', error);
                showError('Failed to save table AI model');
              }
            }}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Save Table AI Model
          </button>
        </div>
      </div>

      {/* Model Information */}
      <div className="mt-8 p-4 bg-gray-50 rounded-lg">
        <h3 className="text-sm font-medium text-gray-700 mb-2">Current Configuration:</h3>
        <div className="space-y-2 text-sm text-gray-600">
          <p>Spreadsheet Model: {spreadsheetModels.spreadsheetModel || 'Not configured'}</p>
          <p>Table AI Model: {spreadsheetModels.tableAiModel || 'Not configured'}</p>
          <p className="text-xs text-gray-500 mt-4">
            Last updated: {new Date().toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  ) : selectedTemplate ? (
    <div>
      {/* Template Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-bold text-gray-900">
            {templates[selectedTemplate]?.name || 'New Template'}
          </h2>
          <p className="text-sm text-gray-500 mt-1">
            Configure the prompt template and model settings
          </p>
        </div>
        <button
          onClick={handleSave}
          className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
        >
          <Save className="h-5 w-5 mr-2" />
          Save Changes
        </button>
      </div>


                    {/* Status Messages */}
                    {error && (
                      <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center">
                        <AlertCircle className="h-5 w-5 mr-2" />
                        {error}
                      </div>
                    )}
                    {success && (
                      <div className="mb-4 p-3 bg-green-50 text-green-700 rounded-lg flex items-center">
                        <Check className="h-5 w-5 mr-2" />
                        {success}
                      </div>
                    )}
                    {/* Model Configuration Section */}
                    <div className="space-y-6">
                      {/* Basic Model Settings */}
                      <div className="grid grid-cols-2 gap-6">
                        {/* Model Selection */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Model
                            <span className="text-red-500 ml-1">*</span>
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Select the AI model to use for generation" />
                            </span>
                          </label>
                          <input
                            type="text"
                            value={promptConfig.model}
                            onChange={(e) => handleModelConfigChange('model', e.target.value)}
                            placeholder="e.g., google/gemini-pro-1.0"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                          />
                        </div>

                        {/* Max Tokens */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Max Tokens
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Maximum number of tokens to generate" />
                            </span>
                          </label>
                          <input
                            type="number"
                            value={promptConfig.max_tokens}
                            onChange={(e) => handleModelConfigChange('max_tokens', e.target.value)}
                            min="1"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                          />
                        </div>
                      </div>

                      {/* Advanced Model Parameters */}
                      <div className="grid grid-cols-2 gap-6">
                        {/* Temperature */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Temperature
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Controls randomness (0-2). Lower = more focused, Higher = more creative" />
                            </span>
                          </label>
                          <input
                            type="number"
                            value={promptConfig.temperature}
                            onChange={(e) => handleModelConfigChange('temperature', e.target.value)}
                            min="0"
                            max="2"
                            step="0.1"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                          />
                        </div>

                        {/* Top P */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Top P
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Nucleus sampling threshold (0-1)" />
                            </span>
                          </label>
                          <input
                            type="number"
                            value={promptConfig.top_p}
                            onChange={(e) => handleModelConfigChange('top_p', e.target.value)}
                            min="0"
                            max="1"
                            step="0.05"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                          />
                        </div>

                        {/* Top K */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Top K
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Limits vocabulary to top K tokens" />
                            </span>
                          </label>
                          <input
                            type="number"
                            value={promptConfig.top_k}
                            onChange={(e) => handleModelConfigChange('top_k', e.target.value)}
                            min="1"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                          />
                        </div>

                        {/* Frequency Penalty */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Frequency Penalty
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Penalizes frequent tokens (-2 to 2)" />
                            </span>
                          </label>
                          <input
                            type="number"
                            value={promptConfig.frequency_penalty}
                            onChange={(e) => handleModelConfigChange('frequency_penalty', e.target.value)}
                            min="-2"
                            max="2"
                            step="0.1"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                          />
                        </div>

                        {/* Presence Penalty */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Presence Penalty
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Penalizes repeated tokens (-2 to 2)" />
                            </span>
                          </label>
                          <input
                            type="number"
                            value={promptConfig.presence_penalty}
                            onChange={(e) => handleModelConfigChange('presence_penalty', e.target.value)}
                            min="-2"
                            max="2"
                            step="0.1"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                          />
                        </div>
                      </div>
                      {/* Messages Configuration */}
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <label className="block text-sm font-medium text-gray-700">
                            Messages
                            <span className="text-red-500 ml-1">*</span>
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Configure conversation messages for the model" />
                            </span>
                          </label>
                          <button
                            onClick={addMessage}
                            className="flex items-center px-3 py-1 text-sm bg-purple-100 text-purple-700 rounded-lg hover:bg-purple-200"
                          >
                            <Plus className="h-4 w-4 mr-1" />
                            Add Message
                          </button>
                        </div>

                        <div className="space-y-4">
                          {promptConfig.messages.map((message, index) => (
                            <div key={index} className="relative p-4 border border-gray-200 rounded-lg">
                              <div className="grid grid-cols-12 gap-4">
                                {/* Role Selection */}
                                <div className="col-span-3">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Role
                                  </label>
                                  <select
                                    value={message.role}
                                    onChange={(e) => updateMessageRole(index, e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                                  >
                                    <option value="user">User</option>
                                    <option value="assistant">Assistant</option>
                                    <option value="system">System</option>
                                  </select>
                                </div>

                                {/* Message Content */}
                                <div className="col-span-9">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Content
                                  </label>
                                  <div className="relative">
                                    <textarea
                                      value={message.content[0].text}
                                      onChange={(e) => updateMessageContent(index, e.target.value)}
                                      rows={3}
                                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                                      placeholder="Enter message content..."
                                    />
                                    {promptConfig.messages.length > 1 && (
                                      <button
                                        onClick={() => removeMessage(index)}
                                        className="absolute -right-2 -top-2 p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
                                      >
                                        <Minus className="h-4 w-4" />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Stop Sequences */}
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <label className="block text-sm font-medium text-gray-700">
                            Stop Sequences
                            <span className="ml-2 inline-flex items-center">
                              <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" 
                                title="Sequences that will stop generation when encountered" />
                            </span>
                          </label>
                          <button
                            onClick={addStopSequence}
                            className="flex items-center px-3 py-1 text-sm bg-purple-100 text-purple-700 rounded-lg hover:bg-purple-200"
                          >
                            <Plus className="h-4 w-4 mr-1" />
                            Add Stop Sequence
                          </button>
                        </div>

                        <div className="space-y-2">
                          {promptConfig.stop.map((sequence, index) => (
                            <div key={index} className="flex items-center space-x-2">
                              <input
                                type="text"
                                value={sequence}
                                onChange={(e) => updateStopSequence(index, e.target.value)}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                                placeholder="Enter stop sequence..."
                              />
                              <button
                                onClick={() => removeStopSequence(index)}
                                className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                              >
                                <Trash2 className="h-4 w-4" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* General Template Settings */}
                      <div className="pt-6 border-t border-gray-200">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">
                          Template Settings
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          {/* Template Name */}
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Template Name
                            </label>
                            <input
                              type="text"
                              value={templates[selectedTemplate]?.name || ''}
                              onChange={(e) => {
                                setTemplates(prev => ({
                                  ...prev,
                                  [selectedTemplate]: {
                                    ...prev[selectedTemplate],
                                    name: e.target.value
                                  }
                                }));
                              }}
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                            />
                          </div>

                          {/* Points Cost */}
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Points Cost (per word)
                            </label>
                            <input
                              type="number"
                              min="1"
                              max="5"
                              value={templates[selectedTemplate]?.pointsCost || 1}
                              onChange={(e) => {
                                setTemplates(prev => ({
                                  ...prev,
                                  [selectedTemplate]: {
                                    ...prev[selectedTemplate],
                                    pointsCost: parseInt(e.target.value)
                                  }
                                }));
                              }}
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                            />
                          </div>

                          {/* Template Description */}
                          <div className="col-span-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Description
                            </label>
                            <textarea
                              rows={3}
                              value={templates[selectedTemplate]?.description || ''}
                              onChange={(e) => {
                                setTemplates(prev => ({
                                  ...prev,
                                  [selectedTemplate]: {
                                    ...prev[selectedTemplate],
                                    description: e.target.value
                                  }
                                }));
                              }}
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                            />
                          </div>
                        </div>

                        {/* Template Controls */}
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center space-x-4">
                            {/* Premium Toggle */}
                            <label className="flex items-center">
                              <input
                                type="checkbox"
                                checked={templates[selectedTemplate]?.premium || false}
                                onChange={(e) => {
                                  setTemplates(prev => ({
                                    ...prev,
                                    [selectedTemplate]: {
                                      ...prev[selectedTemplate],
                                      premium: e.target.checked
                                    }
                                  }));
                                }}
                                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                              />
                              <span className="ml-2 text-sm text-gray-700">Premium Feature</span>
                            </label>

                            {/* Active Toggle */}
                            <label className="flex items-center">
                              <input
                                type="checkbox"
                                checked={templates[selectedTemplate]?.active !== false}
                                onChange={(e) => {
                                  setTemplates(prev => ({
                                    ...prev,
                                    [selectedTemplate]: {
                                      ...prev[selectedTemplate],
                                      active: e.target.checked
                                    }
                                  }));
                                }}
                                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                              />
                              <span className="ml-2 text-sm text-gray-700">Active</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-12">
                    <Settings className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      Select a Template
                    </h3>
                    <p className="text-gray-500">
                      Choose a template from the sidebar to configure its settings
                    </p>
                  </div>
                )}

                {/* Points Manager */}
                <AdminPointsManager />
                <PointsResetTester />
              </div>
              </div>
) : activeView === 'users' ? (
  <UserManagementPanel />
) : (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <h2 className="text-xl font-bold text-gray-900 mb-4">Content Editor</h2>
    <p className="text-gray-500">Editor functionality coming soon...</p>
  </div>
)}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;