import { ThemeProvider } from './context/ThemeContext';
import { useTheme } from './context/ThemeContext';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import { useState, useEffect } from 'react';
import { getTemplatePath } from './config/templateConfig';
import TemplateService from './services/TemplateService';
// Navigation Components
import Navbar from './components/Navbar';
import ExcelSpreadsheet from './components/spreadsheet/ExcelSpreadsheet.js';
import PublicNavbar from './components/PublicNavbar';
import Footer from './components/Footer';
import ManagePosts from './components/ManagePosts.js';
import apiService from './services/apiService';
import CSPHead from './components/CSPHead';
import { Toaster } from 'react-hot-toast';
import PaymentHandler from './components/PaymentHandler.js';
import { PaymentSuccessModal, PaymentCancelModal } from './components/PaymentModals';
import Paraphrase from './components/freetools/Paraphrase.js';
import DynamicAISpreadsheet from './components/tools/AISpreadsheet.jsx';
import AISpreadsheetLanding from './pages/ToolLandingPage/AISpreadsheetLanding.js';
import SpreadsheetHero from './components/tools/SpreadsheetHero.js';
import CombinedSpreadsheet from './components/tools/CombinedSpreadsheet.js';
import SpreadsheetManager from './components/tools/SpreadsheetManager.js';
import AdminSetup from './components/AdminSetup.js';
import ChatBot from './components/chatbot.js';

// Page Components
import Blog from './pages/Blog';
import Home from './pages/Home';
import AuthPages from './pages/Auth/AuthPages';
import Dashboard from './pages/Dashboard';
import ContentGenerator from './components/ContentGenerator';
import AIRewriter from './components/AIRewriter';
import HumanWriter from './components/HumanWriter';
import AdminPortal from './components/AdminPortal';
import Editor from './pages/Editor';
import TemplatesGallery from './pages/TemplatesGallery';
import Analytics from './pages/Analytics';
import LoadingSpinner from './components/LoadingSpinner';
import BlogPost from './pages/BlogPost';
import Pricing from './pages/Pricing';
import Support from './pages/Support';
import FreeTools from './components/FreeTools.js';

// Auth Components
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import EmailVerification from './components/EmailVerification';
import AuthCallback from './components/AuthCallback';
import ScrollToTop from './components/ScrollToTop';
import Settings from './pages/Settings';
import ComingSoon from './pages/ComingSoon';
import ComingSoonImageTool from './pages/ToolLandingPage/ComingSoonImageTool';
import './i18n';
// Landing Page Components
import BlogGeneratorLanding from './pages/ToolLandingPage/BlogGeneratorLanding';
import SocialMediaWriterLanding from './pages/ToolLandingPage/SocialMediaWriterLanding';
import EmailNewsletterLanding from './pages/ToolLandingPage/EmailNewsletterLanding';
import ProductDescriptionWriterLanding from './pages/ToolLandingPage/ProductDescriptionWriterLanding';
import AIDetectionBypassLanding from './pages/ToolLandingPage/AIDetectionBypassLanding';
import SeoOptimizerLanding from './pages/ToolLandingPage/SeoOptimizerLanding';
import ContentRewriterLanding from './pages/ToolLandingPage/ContentRewriterLanding';
import ReadabilityImproverLanding from './pages/ToolLandingPage/ReadabilityImproverLanding';
import AdCopyGeneratorLanding from './pages/ToolLandingPage/AdCopyGeneratorLanding';
import LandingPageWriterLanding from './pages/ToolLandingPage/LandingPageWriterLanding';
import SalesCopyWriterLanding from './pages/ToolLandingPage/SalesCopyWriterLanding';
import CTAGeneratorLanding from './pages/ToolLandingPage/CTAGeneratorLanding';
import AboutPageWriterLanding from './pages/ToolLandingPage/AboutPageWriterLanding';
import ServicePageCreatorLanding from './pages/ToolLandingPage/ServicePageCreatorLanding';
import FaqGeneratorLanding from './pages/ToolLandingPage/FaqGeneratorLanding';
import FeaturesPageWriter from './pages/ToolLandingPage/FeaturesPageWriter';
import PointsManagement from './pages/PointsManagement';
import GenericTemplateWriter from './components/tools/GenericTemplateWriter';
import AboutUs from './pages/FooterPages/AboutUs';
import AffiliateProgram from './pages/FooterPages/AffiliateProgram';
import Contact from './pages/FooterPages/Contact';
import Privacy from './pages/FooterPages/Privacy';
import Terms from './pages/FooterPages/Terms';
import RefundPolicy from './pages/FooterPages/RefundPolicy';




// PageWrapper component
const PageWrapper = ({ children }) => {
  const location = useLocation();
  const isDashboardRoute = location.pathname === '/dashboard';
  const isChatbotRoute = location.pathname === '/mybot';
  
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      {children}
      {!isDashboardRoute && !isChatbotRoute && <Footer />}
    </div>
  );
};

// ToolsLayout component
const ToolsLayout = () => {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet />
      </div>
    </div>
  );
};

// Generic template component for dynamic routes
const TemplateComponent = ({ template }) => {
  const Component = require(`./components/tools/GenericTemplateWriter`).default;
  return <Component template={template} />;
};

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  
  useEffect(() => {
    if (user && location.pathname !== '/dashboard') {
      localStorage.setItem('lastProtectedRoute', location.pathname);
    }
  }, [user, location.pathname]);
  
  if (loading) {
    return <LoadingSpinner />;
  }
  
  if (!user) {
    return <Navigate to="/login" />;
  }
  
  return children;
};

// Public Route Component
const PublicRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <LoadingSpinner />;
  }
  
  if (user) {
    const lastRoute = localStorage.getItem('lastProtectedRoute');
    return <Navigate to={lastRoute || '/dashboard'} />;
  }
  
  return children;
};

const AppContent = () => {
  const { user } = useAuth();
  const [templates, setTemplates] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Add cache busting
  const clearCachedTemplates = () => {
    localStorage.removeItem('promptTemplates');
  };

  // Load templates on mount
  useEffect(() => {
    // Clear cached templates first
    clearCachedTemplates();

    // In App.js, update the loadTemplates function:
const loadTemplates = async () => {
  try {
    setLoading(true);
    // Get token from localStorage
    const token = localStorage.getItem('token');
    
    // Only proceed with the fetch if we have a token
    if (!token) {
      console.log('No authentication token found');
      setTemplates(null);
      return;
    }

    const response = await fetch(`${apiService.getUrl('/api/templates')}`, {
      headers: {
        ...apiService.getHeaders(),
        'Authorization': `Bearer ${token}`,
        'Cache-Control': 'no-store'
      }
    });
    
    if (!response.ok) {
      if (response.status === 401) {
        // Handle unauthorized error silently if user is not logged in
        console.log('User not authenticated for templates');
        setTemplates(null);
        return;
      }
      throw new Error('Failed to fetch templates');
    }

    const data = await response.json();
    setTemplates(data);
    
    // Store in localStorage with timestamp
    const templatesWithTimestamp = {
      data: data,
      timestamp: new Date().getTime()
    };
    localStorage.setItem('promptTemplates', JSON.stringify(templatesWithTimestamp));
  } catch (error) {
    console.error('Error loading templates:', error);
    setError(error.message);
    // Try to load from localStorage as fallback
    const savedTemplates = localStorage.getItem('promptTemplates');
    if (savedTemplates) {
      try {
        const parsed = JSON.parse(savedTemplates);
        if (parsed.data) {
          setTemplates(parsed.data);
        }
      } catch (e) {
        console.error('Error parsing cached templates:', e);
      }
    }
  } finally {
    setLoading(false);
  }
};

    loadTemplates();

    // Listen for template updates
    const handleTemplateUpdate = (e) => {
      if (e.detail) {
        setTemplates(e.detail);
        const templatesWithTimestamp = {
          data: e.detail,
          timestamp: new Date().getTime()
        };
        localStorage.setItem('promptTemplates', JSON.stringify(templatesWithTimestamp));
      }
    };

    window.addEventListener('promptTemplatesUpdate', handleTemplateUpdate);
    return () => {
      window.removeEventListener('promptTemplatesUpdate', handleTemplateUpdate);
      clearCachedTemplates();
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-white dark:bg-gray-900">
      {user ? <Navbar /> : <PublicNavbar />}
      <PageWrapper>
        <main className="flex-grow bg-white dark:bg-gray-900">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<PublicRoute><Home /></PublicRoute>} />
            
            <Route path="/blog" element={<PublicRoute><Blog /></PublicRoute>} />
            <Route path="/pricing" element={<Pricing />} />
             <Route path="/support" element={<Support />} />
             <Route path="/AboutPageWriterLanding" element={<Support />} />
             <Route path="/paraphrase" element={<Paraphrase />} />
             <Route path="/free-tools" element={<FreeTools />} />

             {/* New Footer Routes */}
            <Route path="/about" element={<AboutUs />} />
            <Route path="/affiliate" element={<AffiliateProgram />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refund" element={<RefundPolicy />} />
            <Route path="/landing/image-generator" element={<ComingSoonImageTool />} />
            

            
            {/* Auth Routes */}
            <Route path="/login" element={<PublicRoute><AuthPages defaultView="login" /></PublicRoute>} />
            <Route path="/register" element={<PublicRoute><AuthPages defaultView="register" /></PublicRoute>} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth-callback" element={<AuthCallback />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route path="/admin-setup" element={<AdminSetup />} />
            
            
            {/* Protected Dashboard Routes */}
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/manage-posts" element={<ProtectedRoute><ManagePosts /></ProtectedRoute>} />
            <Route path="/create-content" element={<ProtectedRoute><ContentGenerator /></ProtectedRoute>} />
            <Route path="/admin" element={<ProtectedRoute><AdminPortal /></ProtectedRoute>} />
            <Route path="/editor" element={<ProtectedRoute><Editor /></ProtectedRoute>} />
            <Route path="/editor/:id" element={<ProtectedRoute><Editor /></ProtectedRoute>} />
            <Route path="/templates" element={<ProtectedRoute><TemplatesGallery /></ProtectedRoute>} />
            <Route path="/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path="/images" element={<ProtectedRoute><ComingSoon /></ProtectedRoute>} />
            <Route path="/points" element={<ProtectedRoute><PointsManagement /></ProtectedRoute>} />
            <Route path="/spreadsheet" element={<ProtectedRoute><ExcelSpreadsheet /></ProtectedRoute>} />
            <Route path="/mybot" element={<ProtectedRoute><ChatBot /></ProtectedRoute>} />
            <Route path="/excel" element={<SpreadsheetManager />} />
            
            
            {/* Special Feature Routes */}
            <Route path="/rewriter" element={<ProtectedRoute><AIRewriter /></ProtectedRoute>} />
            <Route path="/human-writer" element={<ProtectedRoute><HumanWriter /></ProtectedRoute>} />
{/* Landing Pages */}
<Route path="/landing/blog-writer" element={<BlogGeneratorLanding />} />
<Route path="/landing/social-media-writer" element={<SocialMediaWriterLanding />} />
<Route path="/landing/email-newsletter" element={<EmailNewsletterLanding />} />
<Route path="/landing/product-description-writer" element={<ProductDescriptionWriterLanding />} />
<Route path="/landing/human-writer" element={<AIDetectionBypassLanding />} />
<Route path="/landing/seo-optimizer" element={<SeoOptimizerLanding />} />
<Route path="/landing/content-rewriter" element={<ContentRewriterLanding />} />
<Route path="/landing/readability-improver" element={<ReadabilityImproverLanding />} />
<Route path="/landing/ad-copy-writer" element={<AdCopyGeneratorLanding />} />
<Route path="/landing/landing-page-writer" element={<LandingPageWriterLanding />} />
<Route path="/landing/sales-copy-writer" element={<SalesCopyWriterLanding />} />
<Route path="/landing/cta-generator" element={<CTAGeneratorLanding />} />
<Route path="/landing/about-page-writer" element={<AboutPageWriterLanding />} />
<Route path="/landing/service-page-writer" element={<ServicePageCreatorLanding />} />
<Route path="/landing/faq-generator" element={<FaqGeneratorLanding />} />
<Route path="/landing/features-page-writer" element={<FeaturesPageWriter />} />
<Route path="/landing/spreadsheet" element={<AISpreadsheetLanding />} />
            {/* Generic Tool Routes */}
            <Route path="/tools/*" element={<ProtectedRoute><ToolsLayout /></ProtectedRoute>}>
  <Route 
    path="*"
    element={<GenericTemplateWriter />}
  />
</Route>
            
            {/* Blog Post Route */}
            <Route path="/blog/:slug" element={<BlogPost />} />
            
            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
      </PageWrapper>
      <ScrollToTop />
      <PaymentHandler />
      {showSuccessModal && (
        <PaymentSuccessModal 
          onClose={() => setShowSuccessModal(false)} 
        />
      )}
      {showCancelModal && (
        <PaymentCancelModal 
          onClose={() => setShowCancelModal(false)}
          onRetry={() => {
            setShowCancelModal(false);
            // Add logic to retry payment
          }}
        />
      )}
    </div>
);
};
// AppWrapper component
const AppWrapper = ({ children }) => {
  const { theme } = useTheme();
  
  return (
    <div className={theme}>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {children}
      </div>
    </div>
  );
};
// Update the Root App Component in App.js
function App() {
  useEffect(() => {
    TemplateService.cleanupOldStorage();
  }, []);

  return (
    <>
      <CSPHead />
      <AuthProvider>
        <ThemeProvider>
            <Router>
              <AppWrapper>
                <AppContent />
              </AppWrapper>
            </Router>
            <Toaster position="top-right" />
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;