import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import SEOHead from '../../components/SEOHead';
import SalesLiveDemo from '../../components/SalesLiveDemo';
import {
  PenTool,
  Target,
  ArrowRight,
  ChevronDown,
  MessageSquare,
  Users,
  Sparkles,
  BarChart,
  Clock,
  Brain,
  Globe,
  Languages,
  Shapes,
  Settings,
  CheckCircle,
  Zap,
  Star,
  TrendingUp,
  FileText,
  Layout,
  Wand2,
  Eye,
  Share2
} from 'lucide-react';

const SalesCopyWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [selectedExample, setSelectedExample] = useState('ecommerce');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // SEO metadata configuration
  const seoData = {
    title: "AI Sales Copy Generator | Create Converting Sales Copy in Seconds",
    description: "Transform your marketing with our AI-powered sales copy generator. Create high-converting copy for ads, emails, and product descriptions instantly. Try free now!",
    keywords: "AI sales copy writer, sales copy generator, AI copywriting, marketing copy generator, sales page generator",
  };

  const heroVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const examples = {
    ecommerce: {
      title: "Product Description",
      before: "Quality leather wallet with multiple compartments.",
      after: "Experience luxury redefined with our handcrafted Italian leather wallet. Featuring 8 strategically designed card slots, a secure RFID-blocking layer, and butter-soft leather that ages beautifully. Perfect for the distinguished professional who demands both style and functionality.",
    },
    saas: {
      title: "SaaS Landing Page",
      before: "Project management software that helps teams work better.",
      after: "Revolutionary AI-powered project management that transforms how teams collaborate. Automate 80% of routine tasks, gain real-time insights, and boost team productivity by 3x. Used by leading companies like Tesla, Apple, and Google to scale their operations effortlessly.",
    },
    ads: {
      title: "Facebook Ad",
      before: "Get our weight loss supplement. Works fast.",
      after: "🔥 Transform Your Body in 90 Days! Join 70,000+ satisfied customers who've discovered the science-backed formula that melts fat 3x faster. Clinically proven, natural ingredients. Limited time offer: Save 40% + Free Shipping! 💪",
    }
  };

  return (
    <>
      <SEOHead
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with 3D Animation */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <motion.div 
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.h1 
              variants={heroVariants}
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
            >
              AI-Powered
              <span className="block">Sales Copy Magic</span>
            </motion.h1>

            <motion.p 
              variants={fadeInUpVariants}
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
            >
              Transform your marketing message into high-converting copy in seconds
            </motion.p>

            <motion.div 
              variants={fadeInUpVariants}
              className="flex flex-col sm:flex-row gap-4 justify-center"
            >
              <Link 
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <Link
  onClick={(e) => {
    e.preventDefault();
    document.getElementById('examples')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  }}
  className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
>
  View Examples
</Link>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div 
              variants={fadeInUpVariants}
              className="mt-12 flex justify-center items-center space-x-8"
            >
              <div className="flex items-center">
                <FileText className="h-5 w-5 text-blue-500 mr-2" />
                <span>2M+ Copies Generated</span>
              </div>
              <div className="flex items-center">
                <Users className="h-5 w-5 text-blue-500 mr-2" />
                <span>50K+ Active Users</span>
              </div>
              <div className="flex items-center">
                <Star className="h-5 w-5 text-blue-500 mr-2" />
                <span>4.9/5 Rating</span>
              </div>
            </motion.div>
          </motion.div>

          {/* Animated background elements */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-blue-500/30 rounded-full filter blur-3xl animate-pulse" />
            <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-500/30 rounded-full filter blur-3xl animate-pulse delay-1000" />
          </div>
        </section>

{/* AI Examples Showcase Section */}
<section className="py-24 bg-black relative" id="examples">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                See the AI Magic
              </h2>
              <p className="text-gray-400 text-xl">
                Watch your copy transform from basic to brilliant
              </p>
            </motion.div>

            <div className="flex justify-center gap-4 mb-8">
              {Object.keys(examples).map((type) => (
                <motion.button
                  key={type}
                  onClick={() => setSelectedExample(type)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`px-6 py-3 rounded-full capitalize transition-all ${
                    selectedExample === type 
                      ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
                      : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
                  }`}
                >
                  {type}
                </motion.button>
              ))}
            </div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="grid md:grid-cols-2 gap-8"
            >
              <div className="bg-gray-800/50 backdrop-blur-xl p-8 rounded-2xl border border-gray-700">
                <div className="flex items-center justify-between mb-6">
                  <h3 className="text-xl font-semibold">Original Copy</h3>
                  <span className="text-gray-400 text-sm">Before AI Magic</span>
                </div>
                <p className="text-gray-300 text-lg">{examples[selectedExample].before}</p>
              </div>

              <div className="bg-gradient-to-br from-blue-900/50 to-purple-900/50 backdrop-blur-xl p-8 rounded-2xl border border-blue-700/50">
                <div className="flex items-center justify-between mb-6">
                  <h3 className="text-xl font-semibold text-blue-400">AI-Enhanced Copy</h3>
                  <span className="text-blue-400 text-sm flex items-center">
                    <Sparkles className="h-4 w-4 mr-1" />
                    Enhanced
                  </span>
                </div>
                <p className="text-gray-200 text-lg leading-relaxed">
                  {examples[selectedExample].after}
                </p>
              </div>
            </motion.div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="mt-12 grid grid-cols-3 gap-6"
            >
              {[
                { metric: "10x Faster", icon: Zap },
                { metric: "3x More Engaging", icon: Users },
                { metric: "2x Higher Conversion", icon: TrendingUp }
              ].map((stat, index) => (
                <div key={index} className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-6 text-center">
                  <stat.icon className="h-8 w-8 text-blue-400 mx-auto mb-3" />
                  <p className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                    {stat.metric}
                  </p>
                </div>
              ))}
            </motion.div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-7xl mx-auto px-4 relative z-10"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Powered by Advanced AI
              </h2>
              <p className="text-gray-400 text-xl">
                Features that transform your copy writing process
              </p>
            </div>

            <motion.div 
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="grid md:grid-cols-3 gap-8"
            >
              {[
                {
                  icon: Brain,
                  title: "Neural Text Generation",
                  description: "Advanced AI models analyze successful copy patterns to generate high-converting content",
                  features: ["Pattern Recognition", "Emotional Analysis", "Conversion Optimization"]
                },
                {
                  icon: Globe,
                  title: "Multi-Channel Ready",
                  description: "Automatically adapt your copy for different platforms and formats",
                  features: ["Social Media", "Email", "Landing Pages", "Ads"]
                },
                {
                  icon: Target,
                  title: "Audience Targeting",
                  description: "Tailored copy that resonates with your specific audience segments",
                  features: ["Demographic Analysis", "Psychographic Profiling", "Intent Mapping"]
                },
                {
                  icon: Layout,
                  title: "Smart Templates",
                  description: "Industry-specific templates that follow proven conversion frameworks",
                  features: ["AIDA Framework", "PAS Formula", "FAB Technique"]
                },
                {
                  icon: Sparkles,
                  title: "Style Adaptation",
                  description: "Match your brand voice while maintaining persuasive power",
                  features: ["Tone Adjustment", "Brand Voice", "Style Consistency"]
                },
                {
                  icon: BarChart,
                  title: "Performance Analytics",
                  description: "Track and optimize your copy's performance across channels",
                  features: ["A/B Testing", "Conversion Tracking", "ROI Analysis"]
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  variants={fadeInUpVariants}
                  className="group relative bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl p-8 rounded-2xl border border-gray-700/50 hover:border-blue-500/50 transition-all"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity" />
                  
                  <feature.icon className="h-12 w-12 text-blue-400 mb-6" />
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-400 mb-6">{feature.description}</p>
                  
                  <ul className="space-y-2">
                    {feature.features.map((item, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <CheckCircle className="h-4 w-4 text-blue-400 mr-2" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        </section>
{/* Interactive Demo Section */}
<section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
              >
                <h2 className="text-4xl md:text-5xl font-bold mb-6">
                  Experience the Future
                  <span className="block text-blue-400">of Copywriting</span>
                </h2>
                <p className="text-gray-400 text-xl mb-8">
                  Watch as AI transforms your ideas into compelling copy in real-time
                </p>
                
                <div className="space-y-6">
                  {[
                    {
                      icon: Target,
                      title: "Define Your Goal",
                      description: "Select your content type and target audience"
                    },
                    {
                      icon: FileText,
                      title: "Input Your Details",
                      description: "Provide key product features and unique selling points"
                    },
                    {
                      icon: Wand2,
                      title: "AI Magic",
                      description: "Watch as AI crafts your perfect copy"
                    },
                    {
                      icon: Eye,
                      title: "Review & Refine",
                      description: "Fine-tune your copy with instant variations"
                    }
                  ].map((step, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 }}
                      className="flex items-start space-x-4"
                    >
                      <div className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center flex-shrink-0">
                        <step.icon className="h-6 w-6 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-lg mb-1">{step.title}</h3>
                        <p className="text-gray-400">{step.description}</p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              <motion.div
  initial={{ opacity: 0, x: 50 }}
  whileInView={{ opacity: 1, x: 0 }}
  viewport={{ once: true }}
  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl p-8 rounded-2xl border border-gray-700/50"
>
  <SalesLiveDemo />
</motion.div>
            </div>
          </div>
        </section>

        {/* Success Stories Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-7xl mx-auto px-4"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Success Stories
              </h2>
              <p className="text-gray-400 text-xl">
                See how businesses are transforming their copy with AI
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  company: "TechStart",
                  metric: "325%",
                  label: "Conversion Rate Increase",
                  description: "AI-generated product descriptions led to a significant boost in e-commerce conversions",
                  logo: "T"
                },
                {
                  company: "GrowthLabs",
                  metric: "10x",
                  label: "Content Output",
                  description: "Scaled their copywriting process while maintaining consistent brand voice",
                  logo: "G"
                },
                {
                  company: "MarketPro",
                  metric: "78%",
                  label: "Time Saved",
                  description: "Reduced copy creation time while improving campaign performance",
                  logo: "M"
                }
              ].map((story, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="group relative bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl p-8 rounded-2xl border border-gray-700/50 hover:border-blue-500/50 transition-all"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity" />
                  
                  <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-2xl font-bold mb-6">
                    {story.logo}
                  </div>
                  
                  <h3 className="text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                    {story.metric}
                  </h3>
                  <p className="text-xl font-semibold mb-4">{story.label}</p>
                  <p className="text-gray-400">{story.description}</p>
                  
                  <div className="mt-6 pt-6 border-t border-gray-700">
                    <p className="text-gray-300 font-medium">{story.company}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>
{/* Testimonials Section */}
<section className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-7xl mx-auto px-4 relative z-10"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Loved by Marketers
              </h2>
              <p className="text-gray-400 text-xl">
                Join thousands of satisfied copywriters and marketers
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  quote: "The AI understands market psychology better than many copywriters I've worked with. It's revolutionized our ad campaigns.",
                  author: "Sarah Chen",
                  role: "Marketing Director",
                  company: "GrowthX",
                  rating: 5
                },
                {
                  quote: "We've seen a 200% improvement in email open rates since using the AI copy generator. The subject lines are pure gold.",
                  author: "Michael Torres",
                  role: "Email Marketing Lead",
                  company: "ConvertPro",
                  rating: 5
                },
                {
                  quote: "The ability to generate multiple variations of copy instantly has transformed our A/B testing process. Game-changer!",
                  author: "Lisa Johnson",
                  role: "CRO Specialist",
                  company: "OptimizeNow",
                  rating: 5
                }
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl p-8 rounded-2xl relative group hover:transform hover:scale-105 transition-all"
                >
                  <div className="flex mb-4">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                    ))}
                  </div>
                  <p className="text-gray-300 italic mb-6">"{testimonial.quote}"</p>
                  <div>
                    <p className="font-semibold">{testimonial.author}</p>
                    <p className="text-sm text-gray-400">{testimonial.role}</p>
                    <p className="text-sm text-gray-400">{testimonial.company}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>

        {/* FAQs Section */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto px-4"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Questions Answered
              </h2>
              <p className="text-gray-400 text-xl">
                Everything you need to know about AI copywriting
              </p>
            </div>

            <div className="space-y-4">
              {[
                {
                  question: "How does the AI create compelling copy?",
                  answer: "Our AI combines advanced language models with proven copywriting frameworks and real-world performance data to generate compelling, conversion-focused copy. It analyzes successful copy patterns and adapts them to your specific needs."
                },
                {
                  question: "Is the generated copy unique?",
                  answer: "Yes! Each piece of copy is uniquely generated based on your inputs. Our AI creates original content while maintaining proven persuasive patterns and your brand voice."
                },
                {
                  question: "What types of copy can I create?",
                  answer: "Our AI can generate various types of copy including product descriptions, email sequences, ad copy, landing page content, social media posts, and more. Each type is optimized for its specific platform and purpose."
                },
                {
                  question: "How many variations can I generate?",
                  answer: "You can generate unlimited variations of your copy. We recommend creating 3-5 versions to test different angles and find what resonates best with your audience."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl rounded-xl overflow-hidden border border-gray-700/50"
                >
                  <button
                    onClick={() => toggleFaq(index)}
                    className="w-full px-6 py-4 text-left flex items-center justify-between hover:bg-blue-500/10 transition-colors"
                  >
                    <span className="font-medium text-lg">{faq.question}</span>
                    <ChevronDown className={`h-5 w-5 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`} />
                  </button>
                  {openFaq === index && (
                    <div className="px-6 pb-4 text-gray-400">
                      {faq.answer}
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>

        {/* Final CTA Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <h2 className="text-4xl md:text-6xl font-bold mb-6">
              Ready to Transform Your Copy?
            </h2>
            <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
              Join thousands of marketers creating high-converting copy with AI
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="flex flex-col sm:flex-row gap-4 justify-center"
            >
              <Link
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Writing Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              <Link
                to="/pricing"
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                View Pricing
              </Link>
            </motion.div>
            <p className="mt-6 text-gray-400">No credit card required • Free plan available</p>
          </motion.div>
        </section>
      </div>
    </>
  );
};

export default SalesCopyWriterLanding;