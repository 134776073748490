import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogClose,
} from '../components/ui/dialog';
import { Button } from '../components/ui/button';
import { AlertCircle, X } from 'lucide-react';


const GrammarCheck = ({ rewrittenContent, onCopyOld, onFixGrammar }) => {
    const [showDialog, setShowDialog] = useState(false);
  
    const handleFixGrammar = async () => {
      setShowDialog(false);
      if (onFixGrammar) {
        const prompt = `without rewriting, fix grammar mistakes. i don't want to change even a single word, just fix mistakes related to commas, comma splice, preposition, agreement, article, or any wrong/misspelled word ${rewrittenContent}`;
        onFixGrammar(prompt);
      }
    };
  
    const handleCopyOld = () => {
      if (onCopyOld) {
        onCopyOld(rewrittenContent);
      }
      setShowDialog(false);
    };
  
    return (
      <>
        <Button 
          onClick={() => setShowDialog(true)}
          className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white dark:bg-blue-700 dark:hover:bg-blue-800"
        >
          <AlertCircle className="w-4 h-4 mr-2" />
          Fix Grammar Issues
        </Button>
  
        <Dialog open={showDialog} onOpenChange={setShowDialog}>
          <DialogContent className="sm:max-w-md dark:bg-gray-800">
            <DialogClose className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
              <X className="h-4 w-4 dark:text-white" />
              <span className="sr-only">Close</span>
            </DialogClose>
            
            <DialogHeader>
              <DialogTitle className="text-xl font-semibold mb-4 dark:text-white">
                Grammar Correction
              </DialogTitle>
            </DialogHeader>
            
            <div className="text-gray-600 dark:text-gray-300 mb-6">
              Due to the advanced training of our AI model, it may introduce strategic linguistic variations. While these don't affect readability, you have the option to standardize the grammar. Please note that this might slightly increase AI detection probability.
            </div>
  
            <div className="flex flex-col gap-3 sm:flex-row sm:justify-end">
              <Button
                variant="outline"
                onClick={handleCopyOld}
                className="w-full sm:w-auto dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:border-gray-600"
              >
                Keep Current Version
              </Button>
              <Button
                onClick={handleFixGrammar}
                className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white dark:bg-blue-700 dark:hover:bg-blue-800"
              >
                Fix Grammar Issues
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default GrammarCheck;