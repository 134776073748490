// UserManagementPanel.js
import React, { useState, useEffect } from 'react';
import { 
  Download, 
  Mail, 
  AlertCircle, 
  Check, 
  Search,
  Filter,
  RefreshCw
} from 'lucide-react';
import apiService from '../services/apiService';

const UserManagementPanel = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [customEmailContent, setCustomEmailContent] = useState('');
  const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(apiService.getUrl('/api/admin/users'), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await response.json();
      console.log('Fetched users data:', data); // Debug log
      setUsers(data);
    } catch (error) {
      setError('Failed to load users: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Filter users based on search term and filter
  const filteredUsers = users.filter(user => {
    const matchesSearch = (
      user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    switch (filter) {
      case 'subscribed':
        return matchesSearch && user.subscription?.status === 'active';
      case 'unsubscribed':
        return matchesSearch && (!user.subscription || user.subscription.status !== 'active');
      case 'inactive':
        const fifteenDaysAgo = new Date();
        fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
        return matchesSearch && new Date(user.lastLogin) < fifteenDaysAgo;
      case 'active':
        return matchesSearch && user.subscription?.status === 'active';
      case 'non-active':
        return matchesSearch && (!user.subscription || user.subscription.status !== 'active');
      default:
        return matchesSearch;
    }
  });
  

  // Export users to CSV
  const exportUsers = () => {
    const csvContent = [
      ['Name', 'Email', 'Subscription Plan', 'Status', 'Last Login'],
      ...filteredUsers.map(user => [
        user.name,
        user.email,
        user.subscription?.planName || 'No Plan',
        user.subscription?.status || 'Inactive',
        new Date(user.lastLogin).toLocaleDateString()
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'users.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Send email functions
  const sendInactiveReminder = async () => {
    try {
      const inactiveUsers = users.filter(user => {
        const fifteenDaysAgo = new Date();
        fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
        return new Date(user.lastLogin) < fifteenDaysAgo;
      });

      const token = localStorage.getItem('token');
      const response = await fetch(apiService.getUrl('/api/admin/send-inactive-reminder'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ userIds: inactiveUsers.map(u => u._id) })
      });

      if (!response.ok) throw new Error('Failed to send inactive reminders');
      
      setSuccess('Inactive user reminders sent successfully');
    } catch (error) {
      setError('Failed to send inactive reminders: ' + error.message);
    }
  };

  const sendSubscriptionReminder = async () => {
    try {
      const unsubscribedUsers = users.filter(user => 
        !user.subscription || user.subscription.status !== 'active'
      );

      const token = localStorage.getItem('token');
      const response = await fetch(apiService.getUrl('/api/admin/send-subscription-reminder'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ userIds: unsubscribedUsers.map(u => u._id) })
      });

      if (!response.ok) throw new Error('Failed to send subscription reminders');
      
      setSuccess('Subscription reminders sent successfully');
    } catch (error) {
      setError('Failed to send subscription reminders: ' + error.message);
    }
  };

  const sendCustomEmail = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(apiService.getUrl('/api/admin/send-custom-email'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          userIds: selectedUsers,
          content: customEmailContent
        })
      });

      if (!response.ok) throw new Error('Failed to send custom email');
      
      setSuccess('Custom emails sent successfully');
      setShowCustomEmailModal(false);
      setCustomEmailContent('');
      setSelectedUsers([]);
    } catch (error) {
      setError('Failed to send custom emails: ' + error.message);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="mb-6">
        <h2 className="text-xl font-bold text-gray-900 mb-2">User Management</h2>
        <p className="text-gray-500">Manage users and send email communications</p>
      </div>

      {/* Status Messages */}
      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}
      {success && (
        <div className="mb-4 p-3 bg-green-50 text-green-700 rounded-lg flex items-center">
          <Check className="h-5 w-5 mr-2" />
          {success}
        </div>
      )}

      {/* Controls */}
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          {/* Search */}
          <div className="relative">
            <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          {/* Filter */}
          <select
  value={filter}
  onChange={(e) => setFilter(e.target.value)}
  className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
>
  <option value="all">All Users</option>
  <option value="active">Active Subscription</option>
  <option value="non-active">No Active Subscription</option>
  <option value="inactive">Inactive (15+ days)</option>
</select>

          {/* Refresh */}
          <button
            onClick={fetchUsers}
            className="p-2 text-gray-600 hover:text-purple-600 rounded-lg"
          >
            <RefreshCw className="h-5 w-5" />
          </button>
        </div>

        <div className="flex items-center space-x-4">

          {/* User Statistics Counter */}
  <div className="ml-auto mr-4 px-3 py-1 bg-gray-100 rounded-lg">
    <span className="text-sm font-medium text-gray-700">
      {users.filter(user => user.subscription?.status === 'active').length}/{users.length}
    </span>
  </div>

          {/* Export Button */}
          <button
            onClick={exportUsers}
            className="flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
          >
            <Download className="h-5 w-5 mr-2" />
            Export CSV
          </button>

          {/* Email Buttons */}
          <button
            onClick={sendInactiveReminder}
            className="flex items-center px-4 py-2 bg-purple-100 text-purple-700 rounded-lg hover:bg-purple-200"
          >
            <Mail className="h-5 w-5 mr-2" />
            Send Inactive Reminder
          </button>

          <button
            onClick={sendSubscriptionReminder}
            className="flex items-center px-4 py-2 bg-purple-100 text-purple-700 rounded-lg hover:bg-purple-200"
          >
            <Mail className="h-5 w-5 mr-2" />
            Send Subscription Reminder
          </button>

          <button
            onClick={() => setShowCustomEmailModal(true)}
            className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            <Mail className="h-5 w-5 mr-2" />
            Send Custom Email
          </button>
          
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedUsers(filteredUsers.map(u => u._id));
                    } else {
                      setSelectedUsers([]);
                    }
                  }}
                  className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Subscription
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Points Balance
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {filteredUsers.map(user => (
              <tr key={user._id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user._id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedUsers([...selectedUsers, user._id]);
                        } else {
                          setSelectedUsers(selectedUsers.filter(id => id !== user._id));
                        }
                      }}
                      className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.subscription?.planName || 'No Plan'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.subscription?.status === 'active'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {user.subscription?.status || 'Inactive'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                     {user.points && typeof user.points === 'object' ?
                       (typeof user.points.balance === 'number' ? user.points.balance.toLocaleString() : '0') :
                       (typeof user.points === 'number' ? user.points.toLocaleString() : '0')
                      }
                   </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
       {/* Custom Email Modal */}
       {showCustomEmailModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Send Custom Email
                </h3>
                <div className="mt-2 px-7 py-3">
                  <textarea
                      placeholder="Enter your custom email content here..."
                      value={customEmailContent}
                      onChange={(e) => setCustomEmailContent(e.target.value)}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    onClick={sendCustomEmail}
                    className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 mr-2">
                    Send Email
                  </button>
                  <button
                    onClick={() => setShowCustomEmailModal(false)}
                    className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default UserManagementPanel;
